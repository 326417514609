import Config from "../conf";
import AuthService from "./AuthService";
import {CacheService} from "./context/CacheService";
import CategoryServiceMock from "./mock/CategoryServiceMock";

export default class CategoryService {

    static getInstance() {
        if (this.instance !== null) {
            if (navigator.userAgent === "ReactSnap") {
                this.instance = new CategoryServiceMock();
            } else {
                this.instance = new CategoryService();
            }

        }

        return this.instance;
    }

    constructor() {
        this.cache = new CacheService("category", 5 * 60);
    }

    loadAllCategories() {
        this.cache.clean();
        return fetch(`${Config.BASE_URL}/usr/category`,
            {
                method: 'GET',
                headers: {"Authorization": AuthService.getToken()},
            }
        ).then(async value => {
            if (value.ok) {
                const data = await value.json();
                this.cache.cacheDate(data);

                return data;
            }
            return value
        }).catch(() => {
            this.cache.cacheDate([]);
        });
    }

    getAllCategories() {
        return this.cache.getCachedData();
    }

    getCategoryById(id) {
        if (this.getAllCategories())
            return this.getAllCategories().filter(cat => cat.id === id)[0];
    }

    updateCategory(cat) {
        return fetch(`${Config.BASE_URL}/usr/category`,
            {
                method: 'PUT',
                headers: {
                    "Authorization": AuthService.getToken(),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(cat)
            }
        );
    }

    delete(id) {
        return fetch(`${Config.BASE_URL}/usr/category?categoryId=${id}`,
            {
                method: 'DELETE',
                headers: {"Authorization": AuthService.getToken()},
            }
        );
    }

    save(cat) {
        return fetch(`${Config.BASE_URL}/usr/category`,
            {
                method: 'POST',
                headers: {
                    "Authorization": AuthService.getToken(),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(cat)
            }
        );
    }


}