import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import css from './Index.module.css';
import React, {useEffect} from 'react';

import chart_icon from '../../img/icons/chart.webp'
import laptop_icon from '../../img/icons/laptop.webp'
import phone_icon from '../../img/icons/phone.webp'
import Footer from "../../components/footer/Footer";
import Slider from "react-slick";
import {useTranslation} from "react-i18next";
import {getCurrentLanguage, saveLanguage} from "../../redux/common";
import {Link, useNavigate} from "react-router-dom";
import AuthService from "../../services/AuthService";

export default function Index() {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    useEffect(() => {
        AuthService.redirectIfAuth(navigate);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const settings = {
        dots: false,
        infinite: true,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
        cssEase: "linear",
        fade: true,
        pauseOnHover: false
    };

    return (
        <div className={css.wrapper}>
            <div className={css.slick_wrapper}>
                <div>
                    <form className={css.change_language_form} onChange={event => {
                        const lng = event.currentTarget.language.value;
                        i18n.changeLanguage(lng)
                            .then(() => saveLanguage(lng));
                    }}>
                        <select name="language" id="language" defaultValue={getCurrentLanguage()}>
                            <option value="ru">ru</option>
                            <option value="en">en</option>
                            <option value="kz">kz</option>
                        </select>
                    </form>
                </div>
                <Slider {...settings} className="p-0 m-0">
                    <div className={`${css.background_img} ${css.bg1}`}>
                        <div className="container-fluid h-100">
                            <div className="row h-100">
                                <div className="col-12 col-md-6">
                                    <div className={css.welcome_text}>
                                        <h1>Finance Helper</h1>
                                        <h2 className={css.title}>{t('hello.text')}</h2>
                                        <h3>{t('hello.subtext')}</h3>

                                        <div className={css.auth_section}>
                                            <Link to={'/auth/login'}
                                               className="btn btn-primary">{t('btn.login')}</Link>

                                            <Link to={'/auth/registration'}
                                               className="btn btn-primary-reverse">{t('btn.registration')}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={`${css.background_img} ${css.bg2}`}>
                        <div className="container-fluid h-100">
                            <div className="row h-100">
                                <div className="col-12 col-md-6">
                                    <div className={css.welcome_text}>
                                        <h2 className={css.h1_title}>Finance Helper</h2>
                                        <h2 className={`${css.title} text-white`}>{t('hello.text')}</h2>
                                        <h3 className='text-white'>{t('hello.subtext')}</h3>

                                        <div className={css.auth_section}>
                                            <Link to={'/auth/login'}
                                               className="btn btn-primary">{t('btn.login')}</Link>

                                            <Link to={'/auth/registration'}
                                               className="btn btn-primary-reverse">{t('btn.registration')}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${css.background_img} ${css.bg3}`}>
                        <div className="container-fluid h-100">
                            <div className="row h-100">
                                <div className="col-12 col-md-6">
                                    <div className={css.welcome_text}>
                                        <h2 className={css.h1_title}>Finance Helper</h2>
                                        <h2 className={`${css.title} text-white`}>{t('hello.text')}</h2>
                                        <h3 className='text-white'>{t('hello.subtext')}</h3>

                                        <div className={css.auth_section}>
                                            <Link to={'/auth/login'}
                                               className="btn btn-primary">{t('btn.login')}</Link>

                                            <Link to={'/auth/registration'}
                                               className="btn btn-primary-reverse">{t('btn.registration')}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>


            <section id={css.solutions}>
                <div className="container">
                    <h2 className={`${css.title} `}>{t('section.solutions')}</h2>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className={css.title_icon}>
                                <img src={phone_icon} alt="phone_icon"/>
                            </div>
                            <div className={css.title}>
                                <h3>{t('section.solutions.work_anywhere')}</h3>
                            </div>
                            <div className={css.content}>
                                <p>{t('section.solutions.work_anywhere.text')}</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className={css.title_icon}>
                                <img src={laptop_icon} alt="laptop_icon"/>
                            </div>
                            <div className={css.title}>
                                <h3>{t('section.solutions.all_in_one')}</h3>
                            </div>
                            <div className={css.content}>
                                <p>{t('section.solutions.all_in_one.text')}</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className={css.title_icon}>
                                <img src={chart_icon} alt="chart_icon"/>
                            </div>
                            <div className={css.title}>
                                <h3>{t('section.solutions.advanced_analytics')}</h3>
                            </div>
                            <div className={css.content}>
                                <p>{t('section.solutions.advanced_analytics.text')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*<section id={css.why_choose_section} hidden>*/}
            {/*    <div className="container">*/}
            {/*        <h2 className={`${css.title}`}>Why choose us?</h2>*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-12 col-md-3">*/}
            {/*                <div className={css.title}>*/}
            {/*                    <h3>Quick start</h3>*/}
            {/*                </div>*/}
            {/*                <div className={css.content}>*/}
            {/*                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.*/}
            {/*                        Lorem*/}
            {/*                        Ipsum has been the industry's standard dummy text ever since the 1500s, when*/}
            {/*                        an*/}
            {/*                        unknown printer took a galley of type and scrambled it to make a type*/}
            {/*                        specimen*/}
            {/*                        book. It has survived not only five centuries, but also the leap into*/}
            {/*                        electronic*/}
            {/*                        typesetting, remaining essentially unchanged.*/}
            {/*                    </p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-12 col-md-3">*/}
            {/*                <div className={css.title}>*/}
            {/*                    <h3>Quick start</h3>*/}
            {/*                </div>*/}
            {/*                <div className={css.content}>*/}
            {/*                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.*/}
            {/*                        Lorem*/}
            {/*                        Ipsum has been the industry's standard dummy text ever since the 1500s, when*/}
            {/*                        an*/}
            {/*                        unknown printer took a galley of type and scrambled it to make a type*/}
            {/*                        specimen*/}
            {/*                        book. It has survived not only five centuries, but also the leap into*/}
            {/*                        electronic*/}
            {/*                        typesetting, remaining essentially unchanged.*/}
            {/*                    </p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-12 col-md-3">*/}
            {/*                <div className={css.title}>*/}
            {/*                    <h3>Quick start</h3>*/}
            {/*                </div>*/}
            {/*                <div className={css.content}>*/}
            {/*                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.*/}
            {/*                        Lorem*/}
            {/*                        Ipsum has been the industry's standard dummy text ever since the 1500s, when*/}
            {/*                        an*/}
            {/*                        unknown printer took a galley of type and scrambled it to make a type*/}
            {/*                        specimen*/}
            {/*                        book. It has survived not only five centuries, but also the leap into*/}
            {/*                        electronic*/}
            {/*                        typesetting, remaining essentially unchanged.*/}
            {/*                    </p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-12 col-md-3">*/}
            {/*                <div className={css.title}>*/}
            {/*                    <h3>Quick start</h3>*/}
            {/*                </div>*/}
            {/*                <div className={css.content}>*/}
            {/*                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.*/}
            {/*                        Lorem*/}
            {/*                        Ipsum has been the industry's standard dummy text ever since the 1500s, when*/}
            {/*                        an*/}
            {/*                        unknown printer took a galley of type and scrambled it to make a type*/}
            {/*                        specimen*/}
            {/*                        book. It has survived not only five centuries, but also the leap into*/}
            {/*                        electronic*/}
            {/*                        typesetting, remaining essentially unchanged.*/}
            {/*                    </p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}
            <Footer/>
        </div>
    );

}
