import React from 'react';
import {letterFrequency} from '@visx/mock-data';
import {Group} from '@visx/group';
import {Pie} from '@visx/shape';
import {getNextColor} from "../../redux/ColorUtils";


export default function PieChart({
                                     data = letterFrequency.slice(0, 4),
                                     width = 300,
                                     height = 300,
                                     margin = {top: 20, bottom: 20, left: 20, right: 20},
                                     color = getNextColor(),
                                     radius = 125,
                                     radiusInner = 50,
                                     padAngle = 0.02,
                                     frequency = (d) => d.value
                                 }) {

    return <svg width={width} height={height}>
        {data.map((d, i) => {
            return (
                <Group key={`pie-${i}`} top={width / 2} left={height / 2}>
                    <Pie
                        data={data}
                        pieValue={frequency}
                        // pieSortValues={pieSortValues}
                        outerRadius={radius}
                        innerRadius={radiusInner}
                        cornerRadius={3}
                        padAngle={padAngle}>
                        {(pie) => {
                            return pie.arcs.map((arc, index) => {
                                const {data} = arc.data;
                                const [centroidX, centroidY] = pie.path.centroid(arc);
                                const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;
                                const arcPath = pie.path(arc);
                                // const arcFill = color;
                                return (
                                    <g key={`arc-${data}-${index}`}>
                                        {arc.data.color}
                                        <path d={arcPath} fill={`url(#${arc.data.color.props.id})`}/>
                                        {hasSpaceForLabel && (
                                            <text
                                                x={centroidX}
                                                y={centroidY}
                                                dy=".33em"
                                                fill="#000"
                                                fontSize={22}
                                                textAnchor="middle"
                                                pointerEvents="none">
                                                {arc.data.name}
                                            </text>
                                        )}
                                    </g>
                                );
                            });
                        }
                        }
                    </Pie>
                </Group>
            );
        })}
    </svg>;
}