import 'react-bootstrap';
import './App.css';
import '../node_modules/react-vis/dist/style.css';
import React, {Component, Suspense} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Index from './pages/index/Index'
import HomePage from './pages/home_page/HomePage';
import Login from './pages/auth/login/Login';
import Registration from './pages/auth/registration/Registration';
import AnalyticsPage from "./pages/analytics/AnalyticsPage";
import HistoryPage from "./pages/history/HistoryPage";
import ProfilePage from "./pages/profile/ProfilePage";
import PlanningPage from "./pages/planning/PlanningPage";
import BaseAnalyticsPage from "./pages/analytics/base/BaseAnalyticsPage";
import MLAnalyticsPage from "./pages/analytics/ml/MLAnalyticsPage";
import NotFoundPage from "./pages/code/404/NotFoundPage";
import GoogleDeleteUserPage
    from "./pages/google_delete_user_page/GoogleDeleteUserPage";


export default class App extends Component {
    render() {
        return <Suspense fallback="loading">
            <BrowserRouter>
                <Routes>
                    <Route exact path={''} element={<Index/>}/>

                    <Route exact path={'/auth/login'}
                           element={<Login/>}/>

                    <Route exact path={'/auth/registration'}
                           element={<Registration/>}/>

                    <Route exact path={'/home'}
                           element={<HomePage/>}/>

                    <Route exact path={'/analytics'}
                           element={<AnalyticsPage/>}/>

                    <Route exact path={'/analytics/base'}
                           element={<BaseAnalyticsPage/>}/>

                    <Route exact path={'/analytics/ml'}
                           element={<MLAnalyticsPage/>}/>

                    <Route exact path={'/history'}
                           element={<HistoryPage/>}/>

                    <Route exact path={'/profile'}
                           element={<ProfilePage/>}/>

                    <Route exact path={'/planning'}
                           element={<PlanningPage/>}/>

                    <Route exact path={'/delete/account'}
                           element={<GoogleDeleteUserPage/>}/>

                    <Route exact path="/404" element={<NotFoundPage/>}/>
                </Routes>
            </BrowserRouter>
        </Suspense>
    }
}