import React from "react";
import commonCss from "../../Common.module.css";
import css from "../../pages/home_page/HomePage.module.css";
import {beauty_currency} from "../../redux/common";
import edit_icon from "../../img/icons/edit_icon.webp";
import {useTranslation} from "react-i18next";
import AccountService from "../../services/AccountService";
import CategoryService from "../../services/CategoryService";
import {localISOString2Date} from "../../utils/DateUtils";
import moment from 'moment';
import PlanningService from "../../services/PlanningService";
import Swal from "sweetalert2";

export default function BudgetCard({
                                       budgetItem,
                                       openEditModal = () => {
                                       },
                                       callback = () => {
                                       }
                                   }) {
    const {t} = useTranslation();
    const accountService = AccountService.getInstance();
    const categoryService = CategoryService.getInstance();
    const planningService = PlanningService.getInstance();

    const category = categoryService.getCategoryById(budgetItem.category);
    const account = accountService.getAccountById(budgetItem.account);

    return <>
        <div className={`d-flex flex-column ${commonCss.card} w-100 h-100`}>
            <div className="card-header d-flex justify-content-between align-items-center">
                <h3>{budgetItem.title}</h3>
                <img src={edit_icon} alt="change_icon"
                     className={css.change_icon} onClick={() => {
                    openEditModal()
                }}/>
            </div>
            <div className="card-body d-flex flex-column justify-content-between h-100">
                <div>
                    <p>{budgetItem.text}</p>
                    <p>{t("budget.reminder_date")}: {budgetItem.reminderDate ? moment(localISOString2Date(budgetItem.reminderDate)).format("DD-MM-YYYY HH:mm") : ''}</p>
                    <p>{t("transaction type")}: {t(budgetItem.type)}</p>
                    <p>{t("account")}: {account ? account.name : ''}</p>
                    <p>{t("category")}: {category ? category.name : ''}</p>

                    <div className="d-flex justify-content-between">
                        <p>{t("budget.period")}: {t(budgetItem.period)}</p>
                        <p>
                            <span>{t("reverted")}: </span> {account && account.currency ? beauty_currency(budgetItem.amount, account.currency.currencyCode, "narrowSymbol") : ''}
                        </p>
                    </div>
                </div>

                <div>
                    <button className="btn btn-block btn-light border" onClick={() => {
                        Swal.fire({
                            title: t("please.wait"),
                            html: t("page.loading"),
                            timerProgressBar: true,
                            didOpen: () => {
                                Swal.showLoading();
                            }
                        });
                        planningService.closeItem(budgetItem.id).then(() => Swal.close()).then(callback)
                    }}>{t('plan.close')}</button>
                </div>
            </div>
        </div>
    </>

}