/*eslint no-useless-constructor: "error"*/
import TransactionService from "../TransactionService";

export default class TransactionServiceMock implements TransactionService {

    constructor() {
        this.data = [{
            "id": "622460c5c4281f4b88602d6c",
            "accountName": "Счет в тенге Kaspi",
            "accountId": "6120e5cb85963463d0c151fc",
            "amount": 10490.0,
            "currency": {
                "currencyCode": "KZT",
                "currencySymbol": null,
                "currencyName": "Казахстанский тенге",
                "currencyRate": 1.0
            },
            "categoryName": "Спорт пит",
            "categoryId": "622460a8c4281f4b88602d6b",
            "transactionType": "OUTCOME",
            "transactionDate": "2022-03-06T05:42:00.000+00:00",
            "description": ""
        }, {
            "id": "62246092c4281f4b88602d6a",
            "accountName": "Счет в тенге Kaspi",
            "accountId": "6120e5cb85963463d0c151fc",
            "amount": 60600.0,
            "currency": {
                "currencyCode": "KZT",
                "currencySymbol": null,
                "currencyName": "Казахстанский тенге",
                "currencyRate": 1.0
            },
            "categoryName": "Одежда",
            "categoryId": "60cca6dbbee220416c93d9ab",
            "transactionType": "OUTCOME",
            "transactionDate": "2022-03-06T05:31:00.000+00:00",
            "description": ""
        }, {
            "id": "62246055c4281f4b88602d69",
            "accountName": "Счет в тенге Kaspi",
            "accountId": "6120e5cb85963463d0c151fc",
            "amount": 150.0,
            "currency": {
                "currencyCode": "KZT",
                "currencySymbol": null,
                "currencyName": "Казахстанский тенге",
                "currencyRate": 1.0
            },
            "categoryName": "Логистика",
            "categoryId": "6187ab45803acc10c3e57539",
            "transactionType": "OUTCOME",
            "transactionDate": "2022-03-05T07:18:00.000+00:00",
            "description": ""
        }, {
            "id": "6224603ec4281f4b88602d68",
            "accountName": "Счет в тенге Kaspi",
            "accountId": "6120e5cb85963463d0c151fc",
            "amount": 6000.0,
            "currency": {
                "currencyCode": "KZT",
                "currencySymbol": null,
                "currencyName": "Казахстанский тенге",
                "currencyRate": 1.0
            },
            "categoryName": "Еда",
            "categoryId": "60cca6bebee220416c93d9aa",
            "transactionType": "OUTCOME",
            "transactionDate": "2022-03-05T07:17:00.000+00:00",
            "description": ""
        }, {
            "id": "62246025c4281f4b88602d67",
            "accountName": "Счет в тенге Kaspi",
            "accountId": "6120e5cb85963463d0c151fc",
            "amount": 1845.0,
            "currency": {
                "currencyCode": "KZT",
                "currencySymbol": null,
                "currencyName": "Казахстанский тенге",
                "currencyRate": 1.0
            },
            "categoryName": "Еда",
            "categoryId": "60cca6bebee220416c93d9aa",
            "transactionType": "OUTCOME",
            "transactionDate": "2022-03-04T07:17:00.000+00:00",
            "description": ""
        }, {
            "id": "6220dd0ec4281f4b88602d5a",
            "accountName": "Счет в тенге Kaspi",
            "accountId": "6120e5cb85963463d0c151fc",
            "amount": 10000.0,
            "currency": {
                "currencyCode": "KZT",
                "currencySymbol": null,
                "currencyName": "Казахстанский тенге",
                "currencyRate": 1.0
            },
            "categoryName": "Другие",
            "categoryId": "613878943f18493da12a9703",
            "transactionType": "OUTCOME",
            "transactionDate": "2022-03-03T04:34:00.000+00:00",
            "description": ""
        }, {
            "id": "621fa55dc4281f4b88602d59",
            "accountName": "Счет в тенге Kaspi",
            "accountId": "6120e5cb85963463d0c151fc",
            "amount": 400000.0,
            "currency": {
                "currencyCode": "KZT",
                "currencySymbol": null,
                "currencyName": "Казахстанский тенге",
                "currencyRate": 1.0
            },
            "categoryName": "Зарплата",
            "categoryId": "6120e6b085963463d0c15200",
            "transactionType": "INCOME",
            "transactionDate": "2022-03-02T17:11:57.026+00:00",
            "description": "Зарплата\n"
        }, {
            "id": "6220dd23c4281f4b88602d5b",
            "accountName": "Счет в тенге Kaspi",
            "accountId": "6120e5cb85963463d0c151fc",
            "amount": 2300.0,
            "currency": {
                "currencyCode": "KZT",
                "currencySymbol": null,
                "currencyName": "Казахстанский тенге",
                "currencyRate": 1.0
            },
            "categoryName": "Еда",
            "categoryId": "60cca6bebee220416c93d9aa",
            "transactionType": "OUTCOME",
            "transactionDate": "2022-03-02T08:22:00.000+00:00",
            "description": ""
        }, {
            "id": "6220ddf8c4281f4b88602d5d",
            "accountName": "Счет в тенге Kaspi",
            "accountId": "6120e5cb85963463d0c151fc",
            "amount": 49200.0,
            "currency": {
                "currencyCode": "KZT",
                "currencySymbol": null,
                "currencyName": "Казахстанский тенге",
                "currencyRate": 1.0
            },
            "categoryName": "Другие",
            "categoryId": "613878943f18493da12a9703",
            "transactionType": "OUTCOME",
            "transactionDate": "2022-03-01T15:25:00.000+00:00",
            "description": "Долларовый депозит"
        }, {
            "id": "6220de98c4281f4b88602d5f",
            "accountName": "Счет в тенге Kaspi",
            "accountId": "6120e5cb85963463d0c151fc",
            "amount": 585.0,
            "currency": {
                "currencyCode": "KZT",
                "currencySymbol": null,
                "currencyName": "Казахстанский тенге",
                "currencyRate": 1.0
            },
            "categoryName": "Еда",
            "categoryId": "60cca6bebee220416c93d9aa",
            "transactionType": "OUTCOME",
            "transactionDate": "2022-02-27T15:28:00.000+00:00",
            "description": ""
        }]
    }

    save(transaction) {
        return super.save(transaction);
    }

    update(transaction) {
        return super.update(transaction);
    }

    delete(id) {
        return super.delete(id);
    }

    transactionTable(page, rowPerPage, startDate, endDate, category, account, type) {
        return new Promise((resolve, reject) => {
            resolve(this.data);
        })
    }

    getTransactionSummary() {
        console.log("getTransactionSummary");
        return new Promise((resolve, reject) => {
            resolve({'amountTransactions': 120, 'incomeMoney': 500000, 'outcomeMoney': 300000});
        }).then(value => {
            console.log("getTransactionSummary2");
            return value;
        });
    }

    getLatestTransaction(amount) {
        console.log("getLatestTransaction");
        return new Promise((resolve, reject) => {
            resolve(this.data);
        });
    }
}