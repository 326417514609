import React from 'react';
import {useTranslation} from "react-i18next";
import css from "./NotFoundPage.module.css"

export default function NotFoundPage() {

    const {t} = useTranslation();

    return (
        <div className={css.wrapper}>
            <div className={css.message_container}>
                <div className={css.message_container_text}>
                    <h1>404</h1>
                    <h2>{t("404")}</h2>
                </div>
            </div>
        </div>
    );

}
