import RequestService from "./context/RequestService";
import {CacheService} from "./context/CacheService";
import {localISOString2Date} from "../utils/DateUtils";
import PlanningServiceMock from "./mock/PlanningServiceMock";

export default class PlanningService {
    static instance;

    static getInstance() {
        if (this.instance !== null) {
            if (navigator.userAgent === "ReactSnap") {
                this.instance = new PlanningServiceMock();
            } else {
                this.instance = new PlanningService();
            }
        }

        return this.instance;
    }

    constructor() {
        this.requestService = new RequestService();
        this.cache = new CacheService("plans", 5 * 60)
        this.items = [];
    }

    loadAllPlanItems() {
        this.cache.clean();
        return this.requestService.sendSimpleRequest("/plan", {method: 'GET'})
            .then(async response => this.cache.cacheDate(await response.json()));
    }

    getAllPlanItems() {
        return this.cache.getCachedData().sort((item1, item2) => new Date(item1.reminderDate) > new Date(item2.reminderDate) ? 1 : -1);
    }

    getOpenedPlanItems() {
        return this.getAllPlanItems().filter(item => this.isOpened(item));
    }

    isOpened(item) {
        return item.reminderDate ? new Date(item.reminderDate) <= new Date() : false;
    }

    getOpenedPlanItemsAmount() {
        return this.getOpenedPlanItems().length;
    }

    getItemById(id) {
        return this.requestService.sendSimpleRequest(`/plan/${id}`, {method: 'GET'})
            .then(async response => await response.json());
    }

    saveItem(reminderDate, title, text, account, category, amount, period, type) {
        const body = JSON.stringify({
            reminderDate: localISOString2Date(reminderDate),
            title: title,
            text: text,
            account: account,
            category: category,
            amount: amount,
            period: period,
            type: type
        });

        return this.requestService.sendSimpleRequest("/plan", {method: 'POST', body: body}, true);
    }

    changeItem(id, reminderDate, title, text, account, category, amount, period, type) {
        const body = JSON.stringify({
            id: id,
            reminderDate: localISOString2Date(reminderDate),
            title: title,
            text: text,
            account: account,
            category: category,
            amount: amount,
            period: period,
            type: type
        });
        return this.requestService.sendSimpleRequest("/plan", {method: 'PUT', body: body}, true);
    }

    deleteItem(id) {
        return this.requestService.sendSimpleRequest(`/plan?id=${id}`, {method: 'DELETE'}, true);
    }

    closeItem(id) {
        return this.requestService.sendSimpleRequest(`/plan/close?id=${id}`, {method: 'GET'});
    }
}