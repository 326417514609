import {CacheService} from "./context/CacheService";
import RequestService from "./context/RequestService";
import AccountServiceMock from "./mock/AccountServiceMock";

export default class AccountService {
    static instance;

    static getInstance() {
        if (this.instance !== null) {
            if (navigator.userAgent === "ReactSnap") {
                this.instance = new AccountServiceMock();
            } else {
                this.instance = new AccountService();
            }
        }
        return this.instance;
    }

    constructor() {
        this.requestService = new RequestService();
        this.cache = new CacheService("account", 60 * 5);
    }

    loadAccounts() {
        this.cache.clean();
        return this.requestService.sendSimpleRequest("/usr/account", {method: 'GET'})
            .then(async value => {
                if (value.ok) {
                    const data = await value.json();
                    this.cache.cacheDate(data);

                    return data;
                }
            }).catch(() => {
                this.cache.cacheDate([]);
            })
    }

    getAllAccounts() {
        return this.cache.getCachedData();
    }

    getAccountById(id) {
        if (this.getAllAccounts())
            return this.getAllAccounts().filter(acc => acc.id === id)[0];
    }

    saveAccount(name, balance, cur) {
        const body = {
            name: name,
            balance: balance,
            currency: cur
        };

        return this.requestService.sendSimpleRequest(`/usr/account`, {method: 'POST', body: JSON.stringify(body)});
    }

    updateAccount(acc) {
        acc = JSON.stringify(acc);
        return this.requestService.sendSimpleRequest(`/usr/account`, {method: 'PUT', body: acc});
    }

    deleteAccount(id) {
        return this.requestService.sendSimpleRequest(`/usr/account?accountId=${id}`, {method: 'DELETE'});
    }
}