import React from "react";
import css from "../../HomePage.module.css";
import {useTranslation} from "react-i18next";
import commonCss from "../../../../Common.module.css";

export default function TransactionCard(props) {
    const { t } = useTranslation();

    return <div className="col-md-4 col-12 mb-3 mb-md-0">
            <div className={`${commonCss.card} p-3`}>
                <h5 className={"mb-4"}>{t(props.title)}</h5>
                <div className="text-center d-flex justify-content-around">
                    <span>{props.text}</span>
                    <small>{t(props.secondary_text)}</small>
                </div>
            </div>
        </div>

}