import React from "react";
import {useTranslation} from "react-i18next";
import Modal from "react-modal";
import Swal from "sweetalert2";
import AccountService from "../../services/AccountService";
import {Button, Col, Form, Row} from "react-bootstrap";
import CurrencyService from "../../services/CurrencyService";

export default function AccountForm({
                                        accountData,
                                        currencies,
                                        isShow = false,
                                        closeModal = () => {
                                        },
                                        callBack = () => {
                                        },
                                        callBackDeleted = () => {

                                        }
                                    }) {
    const {t} = useTranslation();

    const accountService = AccountService.getInstance();
    const currencyService = CurrencyService.getInstance();

    let currencyOptions = [];
    if (currencies) currencyOptions = currencies.map(value => <option key={`opt-account-${value.currencyCode}`}
                                                                          value={value.currencyCode}>{value.currencyName}</option>);

    return <>
        <Modal isOpen={isShow}
               ariaHideApp={false}
               contentLabel="Add currency">
            <div className="d-flex justify-content-between">
                <h2>{t('account')}</h2>
                <Button onClick={() => closeModal()} className="bg-transparent font-weight-bold text-dark">&times;</Button>
            </div>

            <Form className="mt-2" onSubmit={event => {
                event.preventDefault();
                if (accountData) {
                    accountService.updateAccount({
                        id: accountData.id,
                        name: event.currentTarget.name.value
                    }).then(callBack);
                } else {
                    accountService.saveAccount(event.currentTarget.name.value,
                        event.currentTarget.balance.value,
                        currencyService.getCurrencyByCode(event.currentTarget.currency ? event.currentTarget.currency.value : ''))
                        .then(callBack)
                }
            }}>
                <Form.Group className="mb-2">
                    <Form.Label htmlFor="account-label">{t('account.name')}</Form.Label>
                    <Form.Control type="text" className="form-control" id="account-label"
                                  name="name"
                                  defaultValue={accountData ? accountData.name : ''}/>
                </Form.Group>

                <Form.Group className="mb-2" hidden={accountData}>
                    <Form.Label htmlFor="balance">{t('account.balance')}</Form.Label>
                    <Form.Control type="number" name="balance" className="form-control" id="balance"/>
                </Form.Group>

                <Form.Group className="mb-2" hidden={accountData}>
                    <Form.Label htmlFor="currency">{t('currency')}</Form.Label>
                    <Form.Select name="currency" id="currency" className="form-control">
                        {currencyOptions}
                    </Form.Select>
                </Form.Group>

                <Form.Group>
                    <Row>
                        <Col>
                            <Button type="submit" className="w-100">{t('btn.save')}</Button>
                        </Col>
                        <Col hidden={!accountData}>
                            <Button type="button" variant={"danger"} className="w-100"
                                    onClick={() => {
                                        Swal.fire({
                                            title: t('account.delete'),
                                            showCancelButton: true,
                                            confirmButtonText: t('btn.delete'),
                                            cancelButtonText: t('btn.cancel'),
                                            html: "<p>" + t('account.after.deleting') + "</p>"
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                accountService.deleteAccount(accountData.id)
                                                    .then(response => {
                                                        if (response.ok) {
                                                            callBackDeleted();
                                                        } else {
                                                            Swal.fire(t('error.SERVER_NOT_AVAILABLE'), '', 'error');
                                                        }
                                                    })
                                            } else if (result.isDenied) {
                                                Swal.close();
                                            }
                                        })
                                    }}>{t('btn.delete')}
                            </Button>
                        </Col>
                    </Row>
                </Form.Group>

            </Form>
        </Modal>
    </>

}