/*eslint no-useless-constructor: "error"*/
import AnalyticService from "../AnalyticService";

export default class AnalyticServiceMock implements AnalyticService{

    amountTransactions(startDate, endDate) {
        return new Promise((resolve, reject)=>{
            resolve({'amountTransactions': 120, 'incomeMoney': 500000, 'outcomeMoney': 300000})
        })
    }

    transactionCashFlow(startDate, endDate, period) {
        return new Promise((resolve, reject)=>{
            resolve([])
        });
    }

    analyticsByAccount(startDate, endDate) {
        return new Promise((resolve, reject)=>{
            resolve([])
        });
    }

    transactionPredict(){
        return new Promise((resolve, reject)=>{
            resolve([])
        });
    }
}