import css from "./AnalyticsPage.module.css"
import React, {useEffect} from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import base_analytic_img from "../../img/analytics_img/base_analytics.png"
import ml_analytics from "../../img/analytics_img/ml_analytics.jpg"
import {useTranslation} from "react-i18next";
import {getCurrentLanguage} from "../../redux/common";
import {Link} from "react-router-dom";


export default function AnalyticsPage() {
    const {t, i18n} = useTranslation();
    useEffect(() => {
        i18n.changeLanguage(getCurrentLanguage());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <div className={css.wrapper}>
        <div className="top_page">
            <Header/>
            <div className="container">
                <div className="row pt-2 pb-2">
                    <div className="col-1 col-md-6">
                        <Link to="/analytics/base">
                            <div className={css.img_page}>
                                <img src={base_analytic_img} alt="base_analytic_img"/>
                                <div className={css.img_text}>
                                    <p>{t('base_analytics')}</p>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-1 col-md-6">
                        <Link to="/analytics/ml">
                            <div className={css.img_page}>
                                <img src={ml_analytics} alt="ml_analytics"/>
                                <div className={css.img_text}>
                                    <p>{t('ml_analytics')}</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>;
}