import Config from '../conf'
import Swal from "sweetalert2";
import RequestService from "./context/RequestService";
import AuthServiceMock from "./mock/AuthServiceMock";

export default class AuthService {
    static instance;

    static getInstance(navigate, t) {
        if (navigator.userAgent === "ReactSnap") {
            this.instance = new AuthServiceMock(navigate, t);
        } else {
            this.instance = new AuthService(navigate, t);
        }
        return this.instance;
    }

    constructor(navigate, t) {
        this.navigate = navigate;
        this.requestService = new RequestService();
        this.t = t;
    }

    login(email, password) {
        Swal.fire({
            title: 'Please wait.',
            html: 'Authentication is in progress.',
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading();
                this.requestService.sendSimpleRequest(
                    `/auth/login`,
                    {
                        method: 'POST',
                        body: JSON.stringify(
                            {
                                email: email,
                                password: password
                            }
                        )
                    },
                ).then(async (data) => {
                    data = await data;
                    if (data.ok) {
                        let response = await data.json();
                        localStorage.setItem("token", response.token);
                        localStorage.setItem("tokenType", response.tokenType);
                        localStorage.setItem("loginDate", new Date().getTime().toString());
                        this.navigate("/home", { replace: true });

                        Swal.close();
                    } else if (data.status === 401) {
                        Swal.fire({title: this.t("login.fail")})
                    } else {
                        Swal.fire({title: this.t("error.SERVER_NOT_AVAILABLE")})
                    }
                }).catch(() => {
                    Swal.fire({title: this.t("login.fail")})
                });
            }
        });
    }

    registration(nickname, birthdate, email, password, passwordConf) {
        if (password !== passwordConf) {
            return;
        }

        const body = {
            nickname: nickname,
            birthdate: birthdate,
            email: email,
            password: password
        };

        Swal.fire({
            title: 'Please wait.',
            html: 'Authentication is in progress.',
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading();
                this.requestService.sendSimpleRequest(`/auth/registration`, {
                    method: 'POST',
                    body: JSON.stringify(body)
                }).then((response) => {
                    if (response.ok) {
                        this.navigate('/auth/login', { replace: true });
                        Swal.close();
                    } else if (response.status === 409) {
                        Swal.fire({
                            title: this.t("registration.user_exists")
                        });
                    } else if (response.status === 400) {
                        response.text().then(t => {
                            let li = "";
                            JSON.parse(t).forEach(req => li += `<li class="text-left list-group-item">${this.t(req)}</li>`);

                            Swal.fire({
                                html: `<ul class="list-group list-group-flush">${li}</ul>`
                            });
                        })
                    } else {
                        Swal.fire({
                            title: this.t("error.SERVER_NOT_AVAILABLE")
                        });
                    }
                }).catch(() => {
                    Swal.fire({
                        title: this.t("error.SERVER_NOT_AVAILABLE")
                    });
                });
            }
        });

    }

    logout() {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenType");
        localStorage.removeItem("loginDate");

        this.navigate("/", { replace: true });
    }

    static getToken() {
        return localStorage.getItem("tokenType") + " " + localStorage.getItem("token");
    }

    static isAuth() {
        let date = localStorage.getItem("loginDate");
        if (date == null) return false;

        date = parseInt(date);

        if (new Date(date + Config.tokenExpTime * 1000) < new Date()) {
            localStorage.removeItem("token");
            localStorage.removeItem("tokenType");
            localStorage.removeItem("loginDate");
            return false;
        }

        return true;
    }

    static redirectIfNotAuth(navigate){
        if (!this.isAuth()) navigate("/auth/login");
    }

    static redirectIfAuth(navigate){
        if (this.isAuth()) navigate("/home");
    }
}