import css from "../AnalyticsPage.module.css"
import React, {useEffect, useRef, useState} from "react";
import Header from "../../../components/header/Header";
import {getCurrentLanguage} from "../../../redux/common";
import {analyticStrDateToDate} from "../../../utils/DateUtils"
import AnalyticService from "../../../services/AnalyticService";
import Footer from "../../../components/footer/Footer";
import {useTranslation} from "react-i18next"
import Swal from "sweetalert2";
import {SingleBarChart} from "../../../components/charts/SingleBarChart";


export default function MLAnalyticsPage() {
    let defaultStartDate = new Date();
    defaultStartDate.setMonth(defaultStartDate.getMonth() - 1);
    const analyticService = AnalyticService.getInstance();
    const containerForBarChart = useRef();
    const {t, i18n} = useTranslation();

    const [cashFlowPredict, setCashFlowPredict] = useState([]);


    useEffect(() => {
        loadData([
            calcTransactionPredict()
        ]);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    function loadData(values) {
        i18n.changeLanguage(getCurrentLanguage()).then(() => {
            Swal.fire({
                title: t("please.wait"),
                html: t("page.loading"),
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading();
                }
            });
        }).then(() => {
            Promise.all(values).then(() => {
                Swal.close();
            })
        })
    }

    function calcTransactionPredict() {
        return analyticService.transactionPredict().then(res => {
            if (res) {
                setCashFlowPredict((res.length !== 0) ? [
                        <div className="pt-2" key={'predict_outcomes'}>
                            <h3 key={'Cash flow'} className="text-center">{t('chart.cash_flow')}</h3>
                            <SingleBarChart
                                key={`BarChart`}
                                width={containerForBarChart.current !== null ? containerForBarChart.current.offsetWidth : 360}
                                height={400}
                                data={res.sort((a, b) => {
                                    return analyticStrDateToDate(a.date.split("T")[0]) > analyticStrDateToDate(b.date.split("T")[0]);
                                }).map((val => {
                                    val.date = new Date(val.date).toLocaleDateString();
                                    val.value = val.predict;
                                    return val;
                                }))}/>
                        </div>
                    ] : []
                );
            }
        })
    }


    return <div className={css.wrapper}>
        <Header/>
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 col-lg-12 pt-3 pb-3">
                    <div ref={containerForBarChart} className="card h-100">
                        {cashFlowPredict}
                    </div>
                </div>
            </div>
        </div>

        <Footer/>
    </div>;
}