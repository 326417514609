import React, {Component} from "react";
import css from "../../HomePage.module.css";
import commonCss from '../../../../Common.module.css';
import {beauty_currency} from "../../../../redux/common";

export default class CurrencyCard extends Component {
    render() {
        return <div className="col-12 col-md-4 align-content-center mt-3">
            <div className={`w-100 ${css.currency_wrapper} ${commonCss.card}`}>
                <div className={`${css.currency_card} w-100`}>
                    <span>{beauty_currency(1, this.props.currency.currencyCode, "code")}</span>
                    <span>{beauty_currency(this.props.currency.currencyRate, "KZT", "narrowSymbol")}</span>
                </div>
            </div>
        </div>
    }
}