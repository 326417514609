import React, {useEffect, useState} from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import css from "./Profile.module.css"
import UserService from "../../services/UserService";
import no_avatar from "../../img/no_usr_icon.webp"
import Modal from "react-modal";
import Swal from "sweetalert2";
import {getCurrentLanguage} from "../../redux/common";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import AuthService from "../../services/AuthService";

export default function ProfilePage() {
    const navigate = useNavigate();

    const userService = UserService.getInstance();

    const [userInfo, setUserInfo] = useState({});
    const [uploadAvatarModal, setUploadAvatarModal] = useState(false);
    const [changePasswordModal, setChangePasswordModal] = useState(false);
    const [file, setFile] = useState();
    const [fileUpload, setFileUpload] = useState();
    const {t, i18n} = useTranslation();

    useEffect(() => {
        AuthService.redirectIfNotAuth(navigate);
        i18n.changeLanguage(getCurrentLanguage());

        userService.getUserInfo().then(value => {
            setUserInfo(value);
        });
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return <div className="wrapper">
        <Header/>

        <div className="container content">
            <div className="main-body">
                <div className="row gutters-sm">
                    <div className="col-md-4 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex flex-column align-items-center text-center">
                                    <img
                                        src={userInfo.image !== null && userInfo.image ? `data:image/jpeg;base64,${userInfo.image}` : no_avatar}
                                        alt="logo"
                                        className={css.avatar_img} id="user_icon"/>
                                    <div className="mt-3">
                                        <h4 id="fio">{userInfo.lastname} {userInfo.firstname} {userInfo.patronymic}</h4>

                                        <button className="btn btn-primary me-2" type="button"
                                                onClick={() => {
                                                    setUploadAvatarModal(true)
                                                }}>{t('btn.change.icon')}
                                        </button>

                                        <button className="btn btn-primary-reverse" type="button"
                                                onClick={() => setChangePasswordModal(true)}>{t('btn.change.password')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-3">
                                        <h6 className="mb-0">{t('page.profile.login')}</h6>
                                    </div>
                                    <div className="col-sm-9 text-secondary">
                                        <span id="nickname">{userInfo.nickname}</span>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <h6 className="mb-0">{t('page.profile.email')}</h6>
                                    </div>
                                    <div className="col-sm-9 text-secondary">
                                        <span id="email">{userInfo.email}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer/>

        <Modal
            isOpen={uploadAvatarModal}
            ariaHideApp={false}
            contentLabel="Change avatar icon">

            <div className="d-flex justify-content-between">
                <h2>Avatar icon</h2>
                <button onClick={() => {
                    setUploadAvatarModal(false)
                }} className="btn font-weight-bold">&times;</button>
            </div>

            <div className="d-flex justify-content-center">
                <img src={file ? file : `data:image/jpeg;base64,${userInfo.image}`}
                     className={css.avatar_img} alt="avatar_next"/>
            </div>

            <form className="mt-2" onSubmit={event => {
                event.preventDefault();
                userService.uploadImage(fileUpload)
                    .then(() => {
                        userService.getUserInfo().then(res => setUserInfo(res));
                        setUploadAvatarModal(false);
                    })
            }}>

                <div className="form-group">
                    <label htmlFor="avatar"></label>
                    <input type="file" className="form-control-file" id="avatar" name="avatar" onChange={event => {

                        setFile(URL.createObjectURL(event.target.files[0]));
                        setFileUpload(event.target.files[0]);

                    }} required/>
                </div>

                <div className="form-group">
                    <button type="submit" className="btn btn-primary w-100 mt-2">{t('btn.save')}</button>
                </div>
            </form>
        </Modal>

        <Modal
            isOpen={changePasswordModal}
            ariaHideApp={false}
            contentLabel="Change password">

            <div className="d-flex justify-content-between">
                <h2>{t('btn.change.password')}</h2>
                <button onClick={() => {
                    setChangePasswordModal(false);
                }} className="btn font-weight-bold">&times;</button>
            </div>

            <form className="mt-2" onSubmit={event => {
                event.preventDefault();

                userService.changePassword(event.target.elements.old_password.value,
                    event.target.elements.new_password.value,
                    event.target.elements.confirm_password.value).then(val => {
                    Swal.fire({
                        title: "Пароль изменен"
                    });
                    setChangePasswordModal(false);
                })
            }}>

                <div className="form-group mt-1">
                    <label htmlFor="old-password">{t('form.password.old')}</label>
                    <input type="password" className="form-control" name="old_password" id="old-password" required/>
                </div>
                <div className="form-group mt-1">
                    <label htmlFor="new-password">{t('form.password.new')}</label>
                    <input type="password" className="form-control" name="new_password" id="new-password" required/>
                </div>
                <div className="form-group mt-1">
                    <label htmlFor="confirm-password">{t('form.password.confirm')}</label>
                    <input type="password" className="form-control" name="confirm_password" id="confirm-password"
                           required/>
                </div>

                <div className="form-group">
                    <button type="submit" className="btn btn-primary w-100 mt-2">{t('btn.save')}</button>
                </div>
            </form>
        </Modal>
    </div>

}