import React, {useState} from 'react';
import {Group} from '@visx/group';
import {BarGroup} from '@visx/shape';
import {AxisBottom} from '@visx/axis';
import cityTemperature from '@visx/mock-data/lib/mocks/cityTemperature';
import {scaleBand, scaleLinear, scaleOrdinal} from '@visx/scale';
import {getRedGradient} from "../../redux/ColorUtils";
import css from "../../pages/analytics/AnalyticsPage.module.css";
import {t} from "i18next";

const red = 'red-color';
export const green = 'green';
export const background = '#fff';
const defaultMargin = {top: 40, right: 0, bottom: 40, left: 0};


export function SingleBarChart({
                             width,
                             height,
                             data = cityTemperature.slice(0, 8),
                             events = false,
                             margin = defaultMargin
                         }) {
    const [outcome, setOutcome] = useState(0);
    const [barDate, setBarDate] = useState(0);
    const [showInfo, setShowInfo] = useState(true);

    if (data.length === 0) return;
    const keys = Object.keys(data[0]).filter(d => d !== 'date');
    const formatDate = (date) => date.toString();
    // accessors
    const getDate = (d) => d.date;
    // scales
    const dateScale = scaleBand({
        domain: data.map(getDate),
        padding: 0.2,
    });
    const cityScale = scaleBand({
        domain: keys,
        padding: 0.1,
    });
    const tempScale = scaleLinear({
        domain: [0, Math.max(...data.map(d => Math.max(...keys.map(key => Number(d[key])))))],
    });
    const colorScale = scaleOrdinal({
        domain: keys,
        range: [green, red],
    });
    const xMax = width - margin.left - margin.right;
    const yMax = height - margin.top - margin.bottom;

    dateScale.rangeRound([0, xMax]);
    cityScale.rangeRound([0, dateScale.bandwidth()]);
    tempScale.range([yMax, 0]);


    return (
        <>
            <div className={css.bar_info}>
                <span>{t('table.header.Date')}: {barDate}</span>
                <span>{t('OUTCOME')}: {showInfo ? outcome : 0}</span>
                {showInfo}
            </div>
            <svg width={width} height={height} key={`bar-chart-${data}`}>
                <rect x={0} y={0} width={width} height={height} fill={background} rx={0}/>

                <Group top={margin.top} left={margin.left} key={"group"}>
                    <BarGroup
                        data={data}
                        key={`bar-group-main`}
                        keys={keys}
                        height={yMax}
                        x0={getDate}
                        x0Scale={dateScale}
                        x1Scale={cityScale}
                        yScale={tempScale}
                        color={colorScale}>
                        {barGroups =>
                            barGroups.map((barGroup, index) => ([getRedGradient('red-color', `grad-${index}`),
                                    <Group key={`bar-group-${index}`} left={barGroup.x0}
                                           onMouseEnter={() => {
                                               setOutcome(barGroup.bars[0].value);
                                               // setBarDate(barGroup.bars.length)
                                               setBarDate(data[barGroup.index].date)
                                               setShowInfo(true);
                                           }}
                                           onMouseOut={() => {
                                               setShowInfo(false);
                                           }}>
                                        {[
                                            barGroup.bars.map((bar, index) => (
                                                [<rect
                                                    key={`bar-group-rect-${index}`}
                                                    x={bar.x}
                                                    y={bar.height < 3 ? bar.y - (3 - bar.height) : bar.y}
                                                    width={bar.width}
                                                    height={Math.max(bar.height, 3)}
                                                    fill={`url(#${bar.color})`}
                                                    rx={4}/>,
                                                ]
                                            )
                                            )
                                        ]}
                                    </Group>]
                            ))
                        }
                    </BarGroup>
                </Group>
                <AxisBottom
                    key={'AxisBottom'}
                    top={yMax + margin.top}
                    tickFormat={formatDate}
                    scale={dateScale}
                    stroke={"#36c9a0"}
                    tickStroke={"#000"}
                    hideAxisLine
                    tickLabelProps={() => ({
                        fill: "#000",
                        fontSize: 11,
                        textAnchor: 'middle',
                    })}
                />
            </svg>
        </>)
}