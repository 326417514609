import css from "../AnalyticsPage.module.css"
import React, {useEffect, useRef, useState} from "react";
import Header from "../../../components/header/Header";
import {beauty_currency, getCurrentLanguage} from "../../../redux/common";
import {analyticStrDateToDate, date2LocalISOString, localISOString2Date} from "../../../utils/DateUtils"
import PieChart from "../../../components/charts/PieChart";
import {getGreenGradient, getRedGradient} from "../../../redux/ColorUtils";
import AnalyticService from "../../../services/AnalyticService";
import {BarChart} from "../../../components/charts/BarChart";
import Footer from "../../../components/footer/Footer";
import {AccountAnalyticCard} from "../../../components/card/AccountAnalyticCard";
import {useTranslation} from "react-i18next"
import Swal from "sweetalert2";
import {Col, Form, Row, Button} from "react-bootstrap";


export default function BaseAnalyticsPage() {
    let defaultStartDate = new Date();
    defaultStartDate.setMonth(defaultStartDate.getMonth() - 1);
    const defaultEndDate = new Date();
    const analyticService = AnalyticService.getInstance();
    const containerForPieChart = useRef();
    const containerForBarChart = useRef();
    const {t, i18n} = useTranslation();

    const [amountTransactionsChart, setAmountTransactionsChart] = useState()
    const period = "DAY";
    const [accountAnalytics, setAccountAnalytics] = useState([]);
    const [cashFlow, setCashFlow] = useState([]);
    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);


    useEffect(() => {
        loadData([
            calcAmountTransactions(startDate, endDate),
            calcTransactionCashFlowByDay(startDate, endDate, period),
            calcAnalyticsByAccount(startDate, endDate)
        ]);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        loadData([
            calcAmountTransactions(startDate, endDate),
            calcTransactionCashFlowByDay(startDate, endDate, period),
            calcAnalyticsByAccount(startDate, endDate)
        ]);
    }, [startDate, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        loadData([
            calcTransactionCashFlowByDay(startDate, endDate, period)
        ])
    }, [period]); // eslint-disable-line react-hooks/exhaustive-deps

    function loadData(values) {
        i18n.changeLanguage(getCurrentLanguage())
            .then(() => {
                Swal.fire({
                    title: t("please.wait"),
                    html: t("page.loading"),
                    timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                })
            }).then(() => {
            Promise.all(values).then(() => {
                Swal.close();
            })
        });
    }

    function analyze(event) {
        event.preventDefault();
        setStartDate(localISOString2Date(event.target.elements.startDate.value));
        setEndDate(localISOString2Date(event.target.elements.endDate.value));
    }

    function calcAmountTransactions(startDate, endDate) {
        return analyticService.amountTransactions(startDate, endDate)
            .then(amountTransaction => {
                setAmountTransactionsChart(<div className="card h-100">
                    <div className="d-flex h-100 p-2">
                        <div className="d-flex align-items-center h-100">
                            <PieChart
                                width={containerForPieChart.current !== null ? Math.min(containerForPieChart.current.offsetWidth * 0.66 - 30, 360) : 360}
                                height={containerForPieChart.current !== null ? Math.min(containerForPieChart.current.offsetWidth * 0.66 - 30, 360) : 360}
                                radius={containerForPieChart.current !== null ? Math.min(containerForPieChart.current.offsetWidth * 0.33 - 40, 180) : 180}
                                radiusInner={containerForPieChart.current !== null ? Math.min(containerForPieChart.current.offsetWidth * 0.166 - 30, 90) : 90}
                                data={[
                                    {
                                        name: beauty_currency(amountTransaction.outcomeMoney, "KZT", "narrowSymbol"),
                                        value: amountTransaction.outcomeMoney,
                                        color: getRedGradient("redGradient")
                                    },
                                    {
                                        name: beauty_currency(amountTransaction.incomeMoney - amountTransaction.outcomeMoney, "KZT", "narrowSymbol"),
                                        value: amountTransaction.incomeMoney - amountTransaction.outcomeMoney,
                                        color: getGreenGradient("green")
                                    }]}/>
                        </div>

                        <div className={css.card_chart_body}>
                            <h3 className="mb-3 text-center w-100">{t('chart.total')}</h3>
                            <div>
                                <p>
                                    <span>{t('INCOME')}: </span> {beauty_currency(amountTransaction.incomeMoney, "KZT", "narrowSymbol")}
                                </p>
                            </div>
                            <div>
                                <p>
                                    <span>{t('balance')}: </span> {beauty_currency(amountTransaction.incomeMoney - amountTransaction.outcomeMoney, "KZT", "narrowSymbol")}
                                </p>
                            </div>
                            <div>
                                <p>
                                    <span>{t('OUTCOME')}: </span> {beauty_currency(amountTransaction.outcomeMoney, "KZT", "narrowSymbol")}
                                </p>
                            </div>
                            <div>
                                <p>
                                    <span>{t('page.analytic.transactions')}: </span> {amountTransaction.amountTransactions}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>)
            });
    }

    function calcTransactionCashFlowByDay(startDate, endDate, period) {
        return analyticService.transactionCashFlow(startDate, endDate, period).then(res => {
            setCashFlow((res.length !== 0) ? [
                    <div className="pt-2">
                        <h3 key={'Cash flow'} className="text-center">{t('chart.cash_flow')}</h3>
                        <BarChart
                            key={`BarChart`}
                            width={containerForBarChart.current !== null ? containerForBarChart.current.offsetWidth : 360}
                            height={400}
                            data={res.sort((a, b) => {
                                return analyticStrDateToDate(a.date).getTime() - analyticStrDateToDate(b.date).getTime();
                            }).map((val => {
                                val.date = analyticStrDateToDate(val.date).toLocaleDateString();
                                return val;
                            }))}/>
                    </div>
                ] : []
            )
        })
    }

    function calcAnalyticsByAccount(startDate, endDate) {
        return analyticService.analyticsByAccount(startDate, endDate).then(res => {
            setAccountAnalytics(res.sort((a, b) => a.name.localeCompare(b.name)).map(value => AccountAnalyticCard(value)));
        });
    }

    return <div className={css.wrapper} key="BaseAnalyticWrapper">
        <Header/>
        <div className="container-fluid">
            <div className="row mb-2">
                <div className="col">
                    <Form className="form-inline" onSubmit={event => analyze(event)}>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label htmlFor="startDate" className="sr-only">Start date</Form.Label>
                                    <Form.Control type="datetime-local" className="form-control" name="startDate"
                                                  id="startDate"
                                                  defaultValue={date2LocalISOString(defaultStartDate).substring(0, 16)}
                                                  required/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label htmlFor="endDate" className="sr-only">End Date</Form.Label>
                                    <Form.Control type="datetime-local" className="form-control" name="endDate"
                                                  id="endDate"
                                                  defaultValue={date2LocalISOString(defaultEndDate).substring(0, 16)}
                                                  required/>
                                </Form.Group>
                            </Col>
                            <Col className="d-flex align-items-end ">
                                <Button type="submit" className="btn btn-primary h-fit">{t('btn.analyze')}</Button>
                            </Col>
                        </Row>

                    </Form>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-lg-6" ref={containerForPieChart}>
                    {amountTransactionsChart}
                </div>
                <div className="col-12 col-lg-6">
                    <div ref={containerForBarChart} className="card h-100">
                        {cashFlow}
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <h3 className="text-center mt-3">{t('page.analytic.accounts')}</h3>
                    <div className="row">
                        {accountAnalytics.length === 0 ? AccountAnalyticCard({data: {}}) : accountAnalytics}
                    </div>
                </div>
            </div>
        </div>

        <Footer/>
    </div>;
}