import RequestService from "./context/RequestService";

export default class ContactUsService {
    constructor() {
        this.requestService = new RequestService();
    }

    saveContact(phone, email, message) {
        const body = {
            message: message,
            phone: phone,
            email: email
        };
        return this.requestService.sendSimpleRequest(`/contact_us`, {method: 'POST', body: JSON.stringify(body)})
    }
}