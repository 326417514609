import RequestService from "./context/RequestService";
import AnalyticServiceMock from "./mock/AnalyticServiceMock";

export default class AnalyticService {
    static instance;

    static getInstance() {
        if (this.instance !== null) {
            if (navigator.userAgent === "ReactSnap") {
                this.instance = new AnalyticServiceMock();
            } else {
                this.instance = new AnalyticService();
            }
        }
        return this.instance;
    }

    constructor() {
        this.requestService = new RequestService();
    }

    amountTransactions(startDate, endDate) {
        return this.requestService.sendSimpleRequest(`/analytics/amount_transactions?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
            {method: 'GET'})
            .then(value => {
                return value.json()
            })
            .catch(() => {
                return {'amountTransactions': 0, 'incomeMoney': 0, 'outcomeMoney': 0}
            });
    }

    transactionCashFlow(startDate, endDate, period) {
        return this.requestService.sendSimpleRequest(`/analytics/transactionCashFlow?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&period=${period}`,
            {method: 'GET'}
        ).then(value => {
            return value.json()
        }).catch(() => {
            return []
        });
    }

    analyticsByAccount(startDate, endDate) {
        return this.requestService.sendSimpleRequest(`/analytics/account_transactions?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
            {method: 'GET',}
        ).then(value => {
            return value.json()
        }).catch(() => {
            return []
        });
    }

    transactionPredict() {
        return this.requestService.sendSimpleRequest(`/ml/get_time_series`,
            {method: 'GET'}
        ).then(value => {
            return value.json()
        }).catch(() => {
            return []
        });
    }
}