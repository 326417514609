import {LinearGradient} from "@visx/gradient";
import React from "react";

const colors = [
    "#65b1b3",
    "#549f79",
    "#ffbc8d",
    "#fa3605",
    "#a71b37",
    "#ac53a8",
    "#36c9a0",
];

let ind = 0;

export function getRandomColor(){
    return colors[Math.floor(Math.random() * (colors.length - 1))]
}

export function getNextColor(){
    let c = colors[ind];
    ind = (ind + 1) % colors.length;
    return c;
}

export function getRedGradient(id, key=''){
    return <LinearGradient key={key} from="#ff416c" to="#ff4b2b" id={id}/>
}

export function getGreenGradient(id){
    return <LinearGradient from="#93f9b9" to="#1d976c" id={id}/>
}