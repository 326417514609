/*eslint no-useless-constructor: "error"*/
import AccountService from "../AccountService";

export default class AccountServiceMock implements AccountService {

    constructor() {
        this.data = [{
            "id": "622237d8c4281f4b88602d65",
            "name": "Счет в тенге",
            "balance": 0,
            "currency": {
                "currencyCode": "KZT",
                "currencySymbol": null,
                "currencyName": "Казахстанский тенге",
                "currencyRate": 1
            }
        },
            {
                "id": "622237d8c4281f4b88602d66",
                "name": "Счет в долларах",
                "balance": 0,
                "currency": {
                    "currencyCode": "USD",
                    "currencySymbol": null,
                    "currencyName": "Доллар сша",
                    "currencyRate": 1
                }
            },
            {
                "id": "622237d8c4281f4b88602d67",
                "name": "Счет в евро",
                "balance": 0,
                "currency": {
                    "currencyCode": "EUR",
                    "currencySymbol": null,
                    "currencyName": "Евро",
                    "currencyRate": 1
                }
            }];
    }
    loadAccounts() {
        return new Promise((resolve, reject) => {
            resolve()
        })
    }


    getAllAccounts() {
        return this.data;
    }

    getAccountById(id) {
        if (this.getAllAccounts())
            return this.getAllAccounts().filter(acc => acc.id === id)[0];
    }

    saveAccount(name, balance, cur) {

    }

    updateAccount(acc) {

    }

    deleteAccount(id) {

    }
}