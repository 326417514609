import React from "react";
import {useTranslation} from "react-i18next";
import Modal from "react-modal";
import PlanningService from "../../services/PlanningService";
import Swal from "sweetalert2";
import {dateISOStr2LocalString} from "../../utils/DateUtils";
import CategoryService from "../../services/CategoryService";
import AccountService from "../../services/AccountService";
import {Button, Col, Form, Row} from "react-bootstrap";

export default function BudgetForm({
                                       budgetItem,
                                       isShow = false,
                                       closeModal = () => {
                                       },
                                       callBack = () => {
                                       },
                                   }) {
    const {t} = useTranslation();
    const budgetService = PlanningService.getInstance();
    const isEdit = budgetItem !== undefined;
    const accounts = AccountService.getInstance().getAllAccounts();
    const categories = CategoryService.getInstance().getAllCategories();

    return <>
        <Modal
            isOpen={isShow}
            ariaHideApp={false}
            onRequestClose={() => closeModal()}
            contentLabel="Add budget item">
            <div className="d-flex justify-content-between">
                <h2>{t('budget_item')}</h2>
                <Button onClick={() => closeModal()}
                        className="btn font-weight-bold">&times;</Button>
            </div>

            <Form className="mt-2" onSubmit={event => {
                event.preventDefault();
                if (isEdit) {
                    budgetService.changeItem(
                        budgetItem.id,
                        event.currentTarget.reminder_date.value,
                        event.currentTarget.title.value,
                        event.currentTarget.text.value,
                        event.currentTarget.account.value,
                        event.currentTarget.category.value,
                        event.currentTarget.amount.value,
                        event.currentTarget.period.value,
                        event.currentTarget.type.value
                    ).then(callBack);
                } else {
                    budgetService.saveItem(
                        event.currentTarget.reminder_date.value,
                        event.currentTarget.title.value,
                        event.currentTarget.text.value,
                        event.currentTarget.account.value,
                        event.currentTarget.category.value,
                        event.currentTarget.amount.value,
                        event.currentTarget.period.value,
                        event.currentTarget.type.value
                    ).then(callBack);
                }
            }}>
                <Form.Group>
                    <Form.Label htmlFor="budget_title">{t('budget.title')}</Form.Label>
                    <Form.Control type="text" className="form-control" id="budget_title"
                                  name="title" defaultValue={isEdit ? budgetItem.title : ''}/>
                </Form.Group>
                <Row>
                    <Col>
                        <Form.Group className={"mt-1 mb-2"}>
                            <Form.Label htmlFor="budget_period">{t('budget.period')}</Form.Label>
                            <Form.Select name="period" id="budget_period" className="form-control">
                                <option value="YEAR"
                                        selected={isEdit && budgetItem.period === "YEAR"}>{t('YEAR')}</option>
                                <option value="MONTH"
                                        selected={isEdit && budgetItem.period === "MONTH"}>{t('MONTH')}</option>
                                <option value="WEEK"
                                        selected={isEdit && budgetItem.period === "WEEK"}>{t('WEEK')}</option>
                                <option value="DAY" selected={isEdit && budgetItem.period === "DAY"}>{t('DAY')}</option>
                                <option value="NONE"
                                        selected={!isEdit || budgetItem.period === "NONE"}>{t('NONE')}</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>

                    <Col>
                        <Form.Group className={"mt-1"}>
                            <Form.Label htmlFor="budget_reminder_date">{t('budget.reminder_date')}</Form.Label>
                            <Form.Control type="datetime-local" className="form-control" id="budget_reminder_date"
                                          name="reminder_date"
                                          defaultValue={isEdit ? dateISOStr2LocalString(budgetItem.reminderDate) : ''}/>
                        </Form.Group>

                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className={"mt-1"}>
                            <Form.Label htmlFor="budget_account">{t('account')}</Form.Label>
                            <Form.Select name="account" id="budget_account" className="form-control">
                                {accounts ? accounts.map(acc => <option key={`account-option-${acc.id}`}
                                                                        value={acc.id}
                                                                        selected={isEdit && budgetItem.account === acc.id}>{acc.name}</option>) : ''}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className={"mt-1"}>
                            <Form.Label htmlFor="budget_category">{t('category')}</Form.Label>
                            <Form.Select name="category" id="budget_category" className="form-control">
                                {categories ? categories.map(cat => <option key={`category-option-${cat.id}`}
                                                                            value={cat.id}
                                                                            selected={isEdit && budgetItem.category === cat.id}>{cat.name}</option>) : ''}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group className={"mt-1"}>
                            <Form.Label htmlFor="budget_type">{t('form.type')}</Form.Label>
                            <Form.Select name="type" id="budget_type" className="form-control">
                                <option value="INCOME"
                                        selected={isEdit && budgetItem.type === "INCOME"}>{t("INCOME")}</option>
                                <option value="OUTCOME"
                                        selected={!isEdit || budgetItem.type === "OUTCOME"}>{t("OUTCOME")}</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className={"mt-1"}>
                            <Form.Label htmlFor="budget_amount">{t('budget.amount')}</Form.Label>
                            <Form.Control type="number" name="amount" className="form-control"
                                          id="budget_amount" defaultValue={isEdit ? budgetItem.amount : ''}/>
                        </Form.Group>
                    </Col>
                </Row>

                <Form.Group className={"mt-1"}>
                    <Form.Label htmlFor="budget_description">{t('budget.description')}</Form.Label>
                    <Form.Control as="textarea" className="form-control" id="budget_description"
                                  name="text" defaultValue={isEdit ? budgetItem.text : ''}/>
                </Form.Group>

                <Form.Group className="mt-2">
                    <div className="row">
                        <div className="col">
                            <Button type="submit" className="w-100">{t('btn.save')}</Button>
                        </div>
                        <div className="col" hidden={!isEdit}>
                            <Button type="button" variant={"danger"} className="w-100"
                                    onClick={() => {
                                        Swal.fire({
                                            title: t('budget.delete'),
                                            showConfirmButton: true,
                                            showCancelButton: true,
                                            confirmButtonText: t('btn.delete'),
                                            cancelButtonText: t('btn.cancel'),
                                            showLoaderOnConfirm: true
                                        }).then((result) => {

                                            if (result.isConfirmed) {
                                                budgetService.deleteItem(budgetItem.id).then(response => {
                                                    if (response.ok) {
                                                        Swal.fire(t('budget.deleted'), '', 'success').then(callBack);
                                                    } else {
                                                        Swal.fire(t('error.SERVER_NOT_AVAILABLE'), '', 'error').then(callBack);
                                                    }
                                                });
                                            } else if (result.isDenied) {
                                                Swal.close();
                                            }
                                        })

                                    }}>
                                {t('btn.delete')}
                            </Button>
                        </div>
                    </div>
                </Form.Group>
            </Form>
        </Modal>
    </>

}