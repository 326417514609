import css from './HomePage.module.css';
import commonCss from '../../Common.module.css';
import "./components/modal/Modal.css"
import React, {useEffect, useState} from 'react';
import Header from "../../components/header/Header";
import CurrencyService from "../../services/CurrencyService";
import TransactionService from "../../services/TransactionService";
import AccountService from "../../services/AccountService";
import edit_icon from "../../img/icons/edit_icon.webp";
import CategoryService from "../../services/CategoryService";
import TransactionCard from "./components/cards/TransactionCard";
import CurrencyCard from "./components/cards/CurrencyCard";
import add_icon from "../../img/icons/add_icon.webp";
import Footer from "../../components/footer/Footer";
import {beauty_currency, calcBalance, getCurrentLanguage} from "../../redux/common";
import Swal from "sweetalert2";
import AdditionalMenu from "../../components/addition_menu/AdditionalMenu";
import AccountCard from "./components/cards/AccountCard";
import {useTranslation} from "react-i18next";
import PlanningService from "../../services/PlanningService";
import BudgetCard from "../../components/card/BudgetCard";
import BudgetForm from "../../components/forms/BudgetForm";
import DataTable from "react-data-table-component";
import {useNavigate} from "react-router-dom";
import AuthService from "../../services/AuthService";
import TransactionForm from "../../components/forms/TransactionForm";
import AccountForm from "../../components/forms/AccountForm";
import CategoryForm from "../../components/forms/CategoryForm";

export default function HomePage() {
    const navigate = useNavigate();

    const currencyService = CurrencyService.getInstance();
    const transactionService = TransactionService.getInstance();
    const accountService = AccountService.getInstance();
    const categoryService = CategoryService.getInstance();
    const planningService = PlanningService.getInstance();

    const [transactionSummary, setTransactionSummary] = useState({
        amountTransactions: 0,
        amountMoney: 0,
        outcomeMoney: 0,
    });

    const [baseCurrency, setBaseCurrency] = useState([]);
    const [accountSection, setAccountSection] = useState([]);
    const [categorySection, setCategorySection] = useState([]);
    const [budgetSection, setBudgetSection] = useState([]);
    const [selectedBudgetItem, setSelectedBudgetItem] = useState(undefined);
    const [isOpenBudgetItemModal, setIsOpenBudgetItemModal] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [accountList, setAccountList] = useState([]);
    const [category, setCategory] = useState({id: '', name: ''});
    const [transaction, setTransaction] = useState(undefined);
    const [account, setAccount] = useState(undefined);
    const [isShowAccountModal, setIsShowAccountModal] = useState(undefined);
    const [latestTransaction, setLatestTransaction] = useState([]);
    const [currencyList, setCurrencyList] = useState([]);
    const [isShowCategoryModal, setIsShowCategoryModal] = useState(false);
    const [isShowTransactionModal, setIsShowTransactionModal] = useState(false);
    const [accountBalance, setAccountBalance] = useState(0);
    const {t, i18n} = useTranslation();


    async function renderTransactionSummary() {
        await transactionService.getTransactionSummary().then(data => {
            setTransactionSummary({
                amountTransactions: data.amountTransactions,
                incomeMoney: data.incomeMoney,
                outcomeMoney: data.outcomeMoney
            });
        });
    }

    function renderTransaction() {
        return transactionService.getLatestTransaction(10).then(res => {
            setLatestTransaction(res.transactions);
        });
    }

    function renderBudget() {
        let budgetItems = planningService.getOpenedPlanItems();
        if (Array.isArray(budgetItems) && budgetItems.length > 0) {
            budgetItems = budgetItems.map(item => {
                return <div className="col-12 col-md-12 col-lg-6 mt-6 mt-2">
                    <BudgetCard budgetItem={item}
                                account={accountService.getAccountById(item.account)}
                                openEditModal={() => {
                                    setSelectedBudgetItem(item);
                                    setIsOpenBudgetItemModal(true);
                                }}
                                callback={() => loadAll()}/>
                </div>
            })

            setBudgetSection(budgetItems);
        } else {
            setBudgetSection([])
        }
    }

    function renderCategory() {
        const categories = categoryService.getAllCategories();
        setCategoryList(categories);

        setCategorySection(categories.map(cat =>
            <div className="col-12 col-md-4 col-lg-3 mt-3" key={cat.id}>
                <div className={`h-100 ${commonCss.card}`}>
                    <div className="card-body pt-2 pb-2 d-flex justify-content-between h-100 align-items-center">
                        <h3>{cat.name}</h3>
                        <img src={edit_icon} alt="change_icon"
                             className={css.change_icon} onClick={() => {
                            setCategory(cat);
                            setIsShowCategoryModal(true);
                        }}/>
                    </div>
                </div>
            </div>));

    }

    function renderAccount() {
        const accounts = accountService.getAllAccounts();
        if (accounts) {
            setAccountList(accounts);
            setAccountBalance(calcBalance(accounts));

            setAccountSection(accounts.map(acc =>
                <div className="col-12 col-md-4 col-lg-3 mt-3" key={`account-card-container-${acc.id}`}>
                    <AccountCard key={`account-card-${acc.id}`}
                                 setChangeAccount={setAccount}
                                 setIsOpenEditAccount={setIsShowAccountModal}
                                 account={acc}/>
                </div>));
        }
    }

    function renderCurrency() {
        const baseCurs = currencyService.getCurrencies(["USD", "EUR", "RUB"]);
        setBaseCurrency(baseCurs.map(value => <CurrencyCard currency={value} key={value.currencyCode}/>));


        const currencies = currencyService.getAllCurrency();
        if (currencies) {
            setCurrencyList(currencies);
        }
    }


    function loadAll() {
        return i18n.changeLanguage(getCurrentLanguage())
            .then(() => {
                Swal.fire({
                    title: t("please.wait"),
                    html: t("page.loading"),
                    timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });
            }).then(async () => {
                return await Promise.all([
                    currencyService.loadAllCurrency(), accountService.loadAccounts(), categoryService.loadAllCategories(),
                    planningService.loadAllPlanItems(), renderTransactionSummary(), renderTransaction(),
                ]).then(() => {
                    renderCurrency();

                    renderAccount();

                    renderBudget();

                    renderCategory();
                    Swal.close();
                })
            });
    }

    useEffect(() => {
        AuthService.redirectIfNotAuth(navigate);
        if (AuthService.isAuth()){
            loadAll();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div className={css.wrapper}>
            <Header/>
            <div className="container">
                <div id="currency_section">
                    <div className="row">
                        {baseCurrency}
                    </div>
                </div>

                <div className="mt-4">
                    <div className="row">
                        <TransactionCard key="balance" title="balance" secondary_text="total balance"
                                         text={accountBalance ? beauty_currency(accountBalance, "KZT", "narrowSymbol") : 0}/>
                        <TransactionCard key="amount" title="INCOME" secondary_text="transactions per month"
                                         text={transactionSummary ? beauty_currency(Math.round(transactionSummary.incomeMoney), "KZT", "narrowSymbol") : 0}/>
                        <TransactionCard key="outcome" title="OUTCOME" secondary_text="transactions per month"
                                         text={transactionSummary ? beauty_currency(Math.round(transactionSummary.outcomeMoney), "KZT", "narrowSymbol") : 0}/>
                    </div>
                </div>

                <div className="mt-4" hidden={budgetSection.length === 0}>
                    <div className="d-flex justify-content-between">
                        <h3>{t('page.home.budget')}</h3>
                        <img className={`${css.add_icon_sm} ${commonCss.card}`} onClick={() => {
                            setSelectedBudgetItem(undefined);
                            setIsOpenBudgetItemModal(true)
                        }} src={add_icon} alt="add-budget"/>
                    </div>
                    <div className="row">
                        {budgetSection}
                        <BudgetForm budgetItem={selectedBudgetItem}
                                    callBack={() => {
                                        setIsOpenBudgetItemModal(false);
                                        loadAll();
                                    }}
                                    closeModal={() => {
                                        setIsOpenBudgetItemModal(false)
                                    }}
                                    isShow={isOpenBudgetItemModal}/>
                    </div>
                </div>


                <div className="mt-4">
                    <h3>{t('page.home.accounts')}</h3>
                    <div className="row">
                        {accountSection}
                        <div className="col-12 col-md-3 mt-3">
                            <div className={`${css.card_add_account} ${commonCss.card}`}>
                                <img onClick={() => {
                                    setAccount(undefined);
                                    setIsShowAccountModal(true);
                                }} src={add_icon}
                                     alt="add-account"/>
                                <AccountForm accountData={account}
                                             currencies={currencyList}
                                             isShow={isShowAccountModal}
                                             closeModal={() => {
                                                 setIsShowAccountModal(false)
                                             }}
                                             callBack={() => {
                                                 loadAll().then(setIsShowAccountModal(false)).then(() => Swal.close())
                                             }}
                                             callBackDeleted={() => {
                                                 setIsShowAccountModal(false);
                                                 Swal.fire(t('account.deleted'), '', 'success');

                                                 accountService.loadAccounts().then(() => renderAccount());
                                                 renderTransaction();
                                             }}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-4">
                    <h3>{t('page.home.categories')}</h3>
                    <div className="row">
                        {categorySection}
                        <div className="col-lg-1 col-md-2 col-3 mt-3">
                            <div className={`${css.card_add_category} ${commonCss.card}`}>
                                <img onClick={() => {
                                    setCategory(undefined);
                                    setIsShowCategoryModal(true);
                                }} src={add_icon}
                                     alt="add-account"/>
                            </div>
                        </div>
                    </div>

                    <CategoryForm
                        categoryData={category}
                        isShow={isShowCategoryModal}
                        closeModal={() => {
                            setIsShowCategoryModal(false);
                        }}
                        callBackSave={() => {
                            loadAll().then(() => setIsShowCategoryModal(false));
                        }}
                        callBackDeleted={() => {
                            categoryService.loadAllCategories().then(() => {
                                renderCategory();
                                renderTransaction();
                                setIsShowCategoryModal(false);
                            })

                            Swal.fire(t('category.deleted'), '', 'success');
                        }}/>
                </div>

                <div className="mt-4">
                    <div className="d-flex justify-content-between mb-2">
                        <h3>{t('page.home.latestTransaction')}</h3>
                        <img hidden={latestTransaction && latestTransaction.length !== 0} src={add_icon}
                             alt="add_transactiion"
                             className={css.add_icon_sm}
                             onClick={() => {
                                 setIsShowTransactionModal(true);
                             }}/>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="card">
                                <div className="card-body">
                                    <DataTable
                                        paginationPerPage={20}
                                        columns={[
                                            {
                                                name: t('table.header.Date'),
                                                minWidth: 150,
                                                selector: (row) => new Date(row['transactionDate']).toLocaleString(),
                                            },{
                                                name: t('table.header.Account'),
                                                selector: (row) => {
                                                    const acc = accountService.getAccountById(row['accountId']);
                                                    if (acc !== undefined)
                                                        return acc.name;
                                                    return "";
                                                },
                                            },
                                            {
                                                name: t('table.header.Category'),
                                                selector: (row) => {
                                                    const cat = categoryService.getCategoryById(row['categoryId']);
                                                    if (cat !== undefined)
                                                        return cat.name;
                                                    return "";
                                                },
                                            },
                                            {
                                                name: t('table.header.Type'),
                                                selector: (row) => t(row['transactionType']),
                                            },
                                            {
                                                name: t('table.header.Amount'),
                                                selector: (row) => row['amount'],
                                            },
                                            {
                                                name: t('table.header.Currency'),
                                                selector: (row) => row['currency']['currencyCode'],
                                            },
                                            {
                                                name: t('table.header.Rate'),
                                                selector: (row) => row['currency']['currencyRate'],
                                            },
                                            {
                                                name: <img src={add_icon} alt="add_transactiion"
                                                           className={css.add_icon}
                                                           onClick={() => {
                                                               setTransaction(undefined);
                                                               setIsShowTransactionModal(true);
                                                           }}/>,
                                                selector: (row) => <img src={edit_icon} alt="edit_transaction"
                                                                               className={css.change_icon}
                                                                               onClick={() => {
                                                                                   setTransaction(row);
                                                                                   setIsShowTransactionModal(true);
                                                                               }
                                                                               }/>,
                                            }
                                        ]}
                                        data={latestTransaction}/>
                                </div>
                            </div>
                            <TransactionForm isShow={isShowTransactionModal}
                                             transactionData={transaction}
                                             categories={categoryList}
                                             accounts={accountList}
                                             closeModal={
                                                 () => {
                                                     setIsShowTransactionModal(false);
                                                 }
                                             }
                                             callBack={
                                                 () => {
                                                     loadAll().then(() => setIsShowTransactionModal(false));

                                                 }
                                             }/>
                        </div>
                    </div>
                </div>
            </div>

            <AdditionalMenu currency={currencyList}/>

            <div className="footer-wrapper mt-3">
                <Footer/>
            </div>
        </div>
    );
}