import css from './Registration.module.css';
import commonCss from '../../../Common.module.css';
import React, {useEffect} from 'react';
import AuthService from "../../../services/AuthService";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {Form} from "react-bootstrap";


export default function Registration() {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const authService = AuthService.getInstance(navigate, t);
    const form = {
        nickname: React.createRef(),
        birthdate: React.createRef(),
        email: React.createRef(),
        password: React.createRef(),
        passwordConf: React.createRef()
    };

    function sendForm(event) {
        event.preventDefault();
        authService.registration(
            form.nickname.current.value,
            form.birthdate.current.value,
            form.email.current.value,
            form.password.current.value,
            form.passwordConf.current.value
        );
    }

    useEffect(() => {
        AuthService.redirectIfAuth(navigate);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={css.wrapper}>
            <section id={css.registration_section}>
                <div className={`container ${commonCss.card} ${css.form}`}>
                    <div className="row">
                        <div className="col">
                            <div className="d-flex align-items-center mb-3">
                                <Link to={"/"}><FontAwesomeIcon size={"1x"} icon={faArrowLeft}
                                                                color={"#5abe91"}/></Link>
                                <h2>{t('page.registration')}</h2>
                            </div>
                            <form onSubmit={(event) => sendForm(event)}>
                                <Form.Group className={"mt-1"}>
                                    <Form.Control placeholder={t('form.nickname')}
                                                  className="form-control" ref={form.nickname}/>
                                </Form.Group>
                                <Form.Group className={"mt-1"}>
                                    <Form.Control placeholder={t('form.email')}
                                                  className="form-control" ref={form.email}/>
                                </Form.Group>
                                <Form.Group className={"mt-1"}>
                                    <Form.Control placeholder={t('form.birthdate')}
                                                  type="datetime-local"
                                                  className="form-control" ref={form.birthdate}/>
                                </Form.Group>
                                <Form.Group className={"mt-1"}>
                                    <Form.Control placeholder={t('form.password')}
                                                  type="password"
                                                  className="form-control" ref={form.password}/>
                                </Form.Group>
                                <Form.Group className={"mt-1"}>
                                    <Form.Control placeholder={t('form.password.confirm')}
                                                  type="password"
                                                  className="form-control" ref={form.passwordConf}/>
                                </Form.Group>

                                <button className="btn btn-primary w-100" type="submit">
                                    {t('btn.registration')}
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col">
                            <Link to={'/auth/login'}>{t('btn.login')}</Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
