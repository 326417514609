import {faCalculator} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import Modal from "react-modal";
import React, {useEffect, useState} from "react";
import CurrencyService from "../../services/CurrencyService";

export default function AdditionalMenu(props) {

    let [isOpenModal, setIsOpenModal] = useState(false);
    let [currencyAmount1, setCurrencyAmount1] = useState(0);
    let [currencyAmount2, setCurrencyAmount2] = useState(0);
    let [currency1, setCurrency1] = useState("");
    let [currency2, setCurrency2] = useState("");

    const currencyService = CurrencyService.getInstance();

    useEffect(() => {
        setCurrency1(localStorage.getItem("currency1"));
        setCurrency2(localStorage.getItem("currency2"));
    }, []);

    function handler(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (name === "currency_amount1") {
            converter(value, true, true);
        } else if (name === "currency_amount2") {
            converter(value, true, false);
        } else if (name === "currency1") {
            localStorage.setItem("currency1", value);
            setCurrency1(value);
            converter(value, false, true);
        } else if (name === "currency2") {
            setCurrency2(value);
            localStorage.setItem("currency2", value);
            converter(value, false, false);
        }
    }

    function converter(value, isNumber, isForward) {
        const defCurrency = props.currency && props.currency.length > 0 ? props.currency[0].currencyCode : '';

        if (isNumber) {
            const cur1 = currencyService.getCurrencyByCode(currency1 ? currency1 : defCurrency);
            const cur2 = currencyService.getCurrencyByCode(currency2 ? currency2 : defCurrency);

            const coef = cur1.currencyRate / cur2.currencyRate;

            let curAmount1 = value;
            let curAmount2 = value;

            if (isForward) {
                curAmount2 = Math.round((curAmount2 * coef) * 100) / 100;
            } else {
                curAmount1 = Math.round((curAmount1 / coef) * 100) / 100;
            }

            setCurrencyAmount1(curAmount1);
            setCurrencyAmount2(curAmount2);
        } else {
            const cur1 = currencyService.getCurrencyByCode(value);
            const cur2 = currencyService.getCurrencyByCode(currency2 ? currency2 : defCurrency);

            if (isForward) {
                const coef = cur1.currencyRate / cur2.currencyRate;

                setCurrencyAmount2(Math.round((currencyAmount1 * coef) * 100) / 100);
            } else {
                const coef = cur2.currencyRate / cur1.currencyRate;
                setCurrencyAmount1(Math.round((currencyAmount2 / coef) * 100) / 100);
            }
        }
    }

    function getCurrencyOptions(currency) {
        if (!currency) return '';
        return currency.map(value => {
            return <option key={`opt-add-menu-account-${value.currencyCode}`}
                           value={value.currencyCode}>
                {value.currencyCode}
            </option>
        });
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '20px',
            marginBottom: '170px',
            transform: 'translate(-50%, -50%)',
            backgroundColor: "#fff",
            minWidth: "unset"
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,0)"
        }
    };

    return <>
        <div className="floating-button">
            <FontAwesomeIcon size={"2x"} icon={faCalculator} onClick={() => setIsOpenModal(true)}/>
        </div>

        <Modal
            isOpen={isOpenModal}
            ariaHideApp={false}
            onRequestClose={() => setIsOpenModal(false)}
            style={customStyles}
            contentLabel="Currency converter">

            <div className="d-flex justify-content-between align-items-center">
                <h5 className="mb-1">Currency converter</h5>
                <button onClick={() => setIsOpenModal(false)}
                        className="btn font-weight-bold mb-1">&times;</button>
            </div>

            <div className="mt-2">
                <div className="form-group form-inline">
                    <input type="number" className="form-control" name="currency_amount1"
                           value={currencyAmount1} onChange={event => handler(event)}/>
                    <select name="currency1" className="form-control" onChange={event => handler(event)}
                            defaultValue={currency1}>
                        {getCurrencyOptions(props['currency'])}
                    </select>
                </div>

                <div className="form-group form-inline">
                    <input type="number" className="form-control" name="currency_amount2"
                           value={currencyAmount2} onChange={event => handler(event)}/>
                    <select name="currency2" className="form-control" onChange={event => handler(event)}
                            defaultValue={currency2}>
                        {getCurrencyOptions(props['currency'])}
                    </select>
                </div>
            </div>
        </Modal>
    </>
}