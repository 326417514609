import Swal from "sweetalert2";
import AuthService from "../AuthService";
import Config from "../../conf";

export default class RequestService {

    sendSimpleRequest(url, settings, showLoading = false) {
        if (!settings.headers) settings.headers = {}

        if (AuthService.getToken())
            settings.headers['Authorization'] = AuthService.getToken();
        if (!settings.headers['Content-Type']) settings.headers['Content-Type'] = 'application/json';
        settings.headers['Access-Control-Allow-Origin'] = '*';

        if (showLoading) {
            Swal.showLoading()
        }

        return fetch(`${Config.BASE_URL}${url}`, settings).then(response => {
            if (showLoading) Swal.close();

            return response;
        });
    }
}