import React, {useEffect, useState} from 'react';

import def_user from "../../img/no_usr_icon.webp";
import AuthService from "../../services/AuthService";
import {Container, Dropdown, Navbar} from "react-bootstrap";
import css from "./Header.module.css"
import UserService from "../../services/UserService";
import {useTranslation} from "react-i18next";
import {getCurrentLanguage, saveLanguage} from "../../redux/common";
import {Link, useNavigate} from "react-router-dom";

export default function Header() {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const authService = AuthService.getInstance(navigate, t);
    const userService = UserService.getInstance();

    const [userIcon, setUserIcon] = useState();

    useEffect(() => {
        if (userService.cache.isActual()) {
            setUserIcon(userService.getImage());
        } else {
            userService.loadImage().then(response => {
                setUserIcon(response);
            })
        }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={css.wrapper}>
            <Navbar expand="lg" sticky="top">
                <Container>
                    <Navbar.Brand href="/" className={css['navbar-brand']}>Fin
                        Helper</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <div className="d-md-flex d-block justify-content-between w-100">
                            <div className="d-md-flex d-block">
                                <Link className="nav-link" to="/home">{t('header.home')}</Link>
                                <Link className="nav-link" to="/planning">{t('header.planning')}</Link>
                                <Link className="nav-link" to="/analytics">{t('header.analytics')}</Link>
                                <Link className="nav-link" to="/history">{t('header.history')}</Link>
                            </div>

                            <div className="d-flex">
                                <form className={css.change_language_form} onChange={event => {
                                    const lng = event.currentTarget.language.value;
                                    i18n.changeLanguage(lng)
                                        .then(() => saveLanguage(lng));
                                }}>
                                    <select name="language" id="language" defaultValue={getCurrentLanguage()}>
                                        <option value="ru">ru</option>
                                        <option value="en">en</option>
                                        <option value="kz">kz</option>
                                    </select>
                                </form>
                                <Dropdown>
                                    <Dropdown.Toggle className={`btn ${css.avatar_menu}`} id={css.menu_btn}>
                                        <img src={userIcon ? userIcon : def_user}
                                             alt="avatar"
                                             className={css.avatar}/>
                                        <span className={`${css.caret} ml-2`}/>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item className="m-0 text-dark" href="/profile">{t('header.profile')}</Dropdown.Item>
                                        <Dropdown.Item className="m-0 text-dark" onClick={() => authService.logout()}>{t('auth.logout')}</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>

                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}
