import React from "react";
import css from "../../HomePage.module.css";
import {beauty_currency} from "../../../../redux/common";
import edit_icon from "../../../../img/icons/edit_icon.webp";
import {useTranslation} from "react-i18next";
import commonCss from '../../../../Common.module.css';

export default function AccountCard(props) {
    const { t } = useTranslation();
    return <>
        <div className={`${commonCss.card} h-100`}>
            <div className="card-header d-flex justify-content-between align-items-center">
                <h3>{props.account ? props.account.name : ''}</h3>
                <img src={edit_icon} alt="change_icon" className={css.change_icon} onClick={() => {
                    props.setChangeAccount(props.account);
                    props.setIsOpenEditAccount(true);
                }}/>
            </div>
            <div className="card-body">
                <p>
                    <span>{t("currency")}: </span> {props.account.currency != null ? props.account.currency.currencyCode : ''}
                </p>
                <p>
                    <span>{t("balance")}: </span> {props.account.currency != null ? beauty_currency(props.account.balance, props.account.currency.currencyCode, "narrowSymbol") : ''}
                </p>
            </div>
        </div>
    </>

}