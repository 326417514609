import moment from 'moment';

export function date2LocalISOString(date) {
    if (!date) return undefined;
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString()
}

export function localISOString2Date(str) {
    if (!str) return undefined;
    return new Date(Date.parse(str))
}

export function dateISOStr2LocalString(str) {
    if (!str) return '';

    let date = new Date(str);



    return moment(date).format("yyyy-MM-DDTHH:mm");
}

export function analyticStrDateToDate(str) {
    str = str.split('-');
    return new Date(parseInt(str[2]), parseInt(str[1]) - 1, parseInt(str[0]))
}

export function datePlusMonth(date, months) {
    date.setMonth(date.getMonth() + months);
    return date;
}

export function datePlusSeconds(date, seconds) {
    date.setSeconds(date.getSeconds() + seconds);
    return date;
}