import React, {useEffect, useState} from 'react';
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import BudgetCard from "../../components/card/BudgetCard";
import css2 from "./PlanningPage.module.css";
import css from "../home_page/HomePage.module.css";
import add_icon from "../../img/icons/add_icon.webp";
import BudgetForm from "../../components/forms/BudgetForm";
import PlanningService from "../../services/PlanningService";
import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";
import {getCurrentLanguage} from "../../redux/common";
import CurrencyService from "../../services/CurrencyService";
import AccountService from "../../services/AccountService";
import CategoryService from "../../services/CategoryService";
import {useNavigate} from "react-router-dom";
import AuthService from "../../services/AuthService";

export default function PlanningPage() {
    const navigate = useNavigate();

    const planningService = PlanningService.getInstance();
    const {t, i18n} = useTranslation();
    const [budgetSection, setBudgetSection] = useState([]);
    const [selectedBudgetItem, setSelectedBudgetItem] = useState(undefined);
    const [isOpenBudgetItemModal, setIsOpenBudgetItemModal] = useState(false);
    const currencyService = CurrencyService.getInstance();
    const accountService = AccountService.getInstance();
    const categoryService = CategoryService.getInstance();

    function renderBudget() {
        const budgetItems = planningService.getAllPlanItems();
        if (Array.isArray(budgetItems))
            setBudgetSection(budgetItems.map(item => {
                return <div className="col-12 col-md-12 col-lg-6 mt-6 mt-2" key={`plan-item-${item.id}`}>
                    <BudgetCard budgetItem={item}
                                openEditModal={() => {
                                    setSelectedBudgetItem(item);
                                    setIsOpenBudgetItemModal(true);
                                }}
                                callback={loadAll}/>
                </div>
            }));
    }

    function loadAll() {
        return i18n.changeLanguage(getCurrentLanguage())
            .then(() => {
                Swal.fire({
                    title: t("please.wait"),
                    html: t("page.loading"),
                    timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });
            }).then(async () => {
                return await Promise.all([
                    currencyService.loadAllCurrency(), accountService.loadAccounts(), categoryService.loadAllCategories(),
                    planningService.loadAllPlanItems()
                ]).then(() => {
                    renderBudget();
                    Swal.close();
                });
            });
    }

    useEffect(() => {
        AuthService.redirectIfNotAuth(navigate);
        loadAll();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return <div className={css2.fit_window}>
        <div className="top_item">

            <Header/>

            <div className="container mb-5 mt-2">
                <div className="mt-4">
                    <div className="d-flex justify-content-between">
                        <h3>{t('page.budget')}</h3>
                        <img className={css.add_icon_sm} onClick={() => {
                            setSelectedBudgetItem(undefined);
                            setIsOpenBudgetItemModal(true)
                        }} src={add_icon} alt="add-budget"/>
                    </div>
                    <div className="row">
                        {budgetSection}
                        <BudgetForm budgetItem={selectedBudgetItem}
                                    callBack={() => {
                                        setIsOpenBudgetItemModal(false);
                                        loadAll();
                                    }}
                                    closeModal={() => {
                                        setIsOpenBudgetItemModal(false)
                                    }}
                                    isShow={isOpenBudgetItemModal}/>
                    </div>
                </div>
            </div>

        </div>
        <Footer/>
    </div>
}

