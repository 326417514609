export function beauty_currency(cur, cur_code, cur_mode) {
    let locale = "";

    if (navigator.languages !== undefined)
        locale = navigator.languages[0];
    try {
        let formatter = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: cur_code ? cur_code : "KZT",
            useGrouping: true,
            currencyDisplay: cur_mode,
            // minimumFractionDigits: 0,
            // maximumFractionDigits: 10,
            // notation: "full",
        });

        return formatter.format(cur ? cur : 0).replaceAll(",", " ");
    } catch (e) {
        return "?";
    }
}


export function calcBalance(accs) {
    if (!accs) return 0;
    let balance = 0;

    accs.forEach(val => balance += val.currency.currencyRate * val.balance);

    return Math.round(balance);
}

export function saveLanguage(lng){
    localStorage.setItem("lng", lng);
}

export function getCurrentLanguage(){
    const lng = localStorage.getItem("lng");
    if (lng) return lng;
    return navigator.language;
}
