import RequestService from "./context/RequestService";
import {CacheService} from "./context/CacheService";
import AuthService from "./AuthService";
import Config from "../conf";
import UserServiceMock from "./mock/UserServiceMock";

export default class UserService {
    static instance;

    static getInstance() {
        if (this.instance !== null) {
            if (navigator.userAgent === "ReactSnap") {
                this.instance = new UserServiceMock();
            } else {
                this.instance = new UserService();
            }
        }

        return this.instance;
    }

    constructor() {
        this.requestService = new RequestService();
        this.cache = new CacheService("user", 0);
    }

    getUserInfo() {
        return this.requestService.sendSimpleRequest(`/usr`, {method: 'GET'})
            .then(value => {
                if (value.ok) {
                    const response = value.json();
                    this.cache.cacheDate(response);
                    return response;
                }
                return {};
            });
    }

    uploadImage(files) {
        const formData = new FormData();
        formData.append('file', files);

        return fetch(`${Config.BASE_URL}/usr/img`,
            {
                method: 'POST',
                headers: {
                    "Authorization": AuthService.getToken()
                },
                body: formData
            });
    }

    changePassword(oldPassword, newPassword, confirmPassword) {
        const body = {
            'oldPassword': oldPassword,
            'newPassword': newPassword,
            'confirmPassword': confirmPassword
        };

        return this.requestService.sendSimpleRequest(`/auth/usr`, {method: 'PUT', body: JSON.stringify(body)});
    }

    loadImage() {
        return this.requestService.sendSimpleRequest(`/usr/img`, {method: 'GET'})
            .then(async request => {
                    if (request.ok) {
                        const image = URL.createObjectURL(await request.blob());
                        let userInfo = this.cache.getCachedData();
                        if (!userInfo) userInfo = {};
                        userInfo.image = image;
                        this.cache.cacheDate(userInfo);
                        return image;
                    }
                }
            ).catch(reason => {
                console.log(`${reason}`)
            });
    }

    getImage() {
        const userInfo = this.cache.getCachedData();
        if (userInfo)
            return userInfo.image;
    }
}