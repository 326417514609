/*eslint no-useless-constructor: "error"*/
import CurrencyService from "../CurrencyService";

export default class CurrencyServiceMock implements CurrencyService {

    constructor() {
        this.data = [{
            "currencyCode": "AUD",
            "currencySymbol": null,
            "currencyName": "Австралийский доллар",
            "currencyRate": 370.93
        }, {
            "currencyCode": "AZN",
            "currencySymbol": null,
            "currencyName": "Азербайджанский манат",
            "currencyRate": 297.28
        }, {
            "currencyCode": "AMD",
            "currencySymbol": null,
            "currencyName": "Армянский драм",
            "currencyRate": 10.02
        }, {
            "currencyCode": "BYN",
            "currencySymbol": null,
            "currencyName": "Белорусский рубль",
            "currencyRate": 162.89
        }, {
            "currencyCode": "BRL",
            "currencySymbol": null,
            "currencyName": "Бразильский реал",
            "currencyRate": 99.43
        }, {
            "currencyCode": "HUF",
            "currencySymbol": null,
            "currencyName": "Венгерских форинтов",
            "currencyRate": 14.22
        }, {
            "currencyCode": "HKD",
            "currencySymbol": null,
            "currencyName": "Гонконгский доллар",
            "currencyRate": 64.41
        }, {
            "currencyCode": "GEL",
            "currencySymbol": null,
            "currencyName": "Грузинский лари",
            "currencyRate": 153.91
        }, {
            "currencyCode": "DKK",
            "currencySymbol": null,
            "currencyName": "Датская крона",
            "currencyRate": 73.97
        }, {
            "currencyCode": "AED",
            "currencySymbol": null,
            "currencyName": "Дирхам оаэ",
            "currencyRate": 137.04
        }, {
            "currencyCode": "USD",
            "currencySymbol": null,
            "currencyName": "Доллар сша",
            "currencyRate": 503.3
        }, {
            "currencyCode": "EUR",
            "currencySymbol": null,
            "currencyName": "Евро",
            "currencyRate": 549.91
        }, {
            "currencyCode": "INR",
            "currencySymbol": null,
            "currencyName": "Индийская рупия",
            "currencyRate": 6.59
        }, {
            "currencyCode": "IRR",
            "currencySymbol": null,
            "currencyName": "Иранский риал",
            "currencyRate": 12.0
        }, {
            "currencyCode": "CAD",
            "currencySymbol": null,
            "currencyName": "Канадский доллар",
            "currencyRate": 395.37
        }, {
            "currencyCode": "CNY",
            "currencySymbol": null,
            "currencyName": "Китайский юань",
            "currencyRate": 79.65
        }, {
            "currencyCode": "KWD",
            "currencySymbol": null,
            "currencyName": "Кувейтский динар",
            "currencyRate": 1660.4
        }, {
            "currencyCode": "KGS",
            "currencySymbol": null,
            "currencyName": "Кыргызский сом",
            "currencyRate": 5.96
        }, {
            "currencyCode": "MYR",
            "currencySymbol": null,
            "currencyName": "Малазийский ринггит",
            "currencyRate": 120.52
        }, {
            "currencyCode": "MXN",
            "currencySymbol": null,
            "currencyName": "Мексиканский песо",
            "currencyRate": 24.04
        }, {
            "currencyCode": "MDL",
            "currencySymbol": null,
            "currencyName": "Молдавский лей",
            "currencyRate": 27.98
        }, {
            "currencyCode": "NOK",
            "currencySymbol": null,
            "currencyName": "Норвежская крона",
            "currencyRate": 56.22
        }, {
            "currencyCode": "PLN",
            "currencySymbol": null,
            "currencyName": "Польский злотый",
            "currencyRate": 112.67
        }, {
            "currencyCode": "SAR",
            "currencySymbol": null,
            "currencyName": "Риял саудовской аравии",
            "currencyRate": 134.15
        }, {
            "currencyCode": "RUB",
            "currencySymbol": null,
            "currencyName": "Российский рубль",
            "currencyRate": 4.16
        }, {
            "currencyCode": "XDR",
            "currencySymbol": null,
            "currencyName": "Сдр",
            "currencyRate": 697.15
        }, {
            "currencyCode": "SGD",
            "currencySymbol": null,
            "currencyName": "Сингапурский доллар",
            "currencyRate": 370.02
        }, {
            "currencyCode": "TJS",
            "currencySymbol": null,
            "currencyName": "Таджикский сомони",
            "currencyRate": 44.66
        }, {
            "currencyCode": "THB",
            "currencySymbol": null,
            "currencyName": "Тайский бат",
            "currencyRate": 15.4
        }, {
            "currencyCode": "TRY",
            "currencySymbol": null,
            "currencyName": "Турецкая лира",
            "currencyRate": 35.48
        }, {
            "currencyCode": "UZS",
            "currencySymbol": null,
            "currencyName": "Узбекских сумов",
            "currencyRate": 4.62
        }, {
            "currencyCode": "UAH",
            "currencySymbol": null,
            "currencyName": "Украинская гривна",
            "currencyRate": 16.95
        }, {
            "currencyCode": "GBP",
            "currencySymbol": null,
            "currencyName": "Фунт стерлингов соединенного королевства",
            "currencyRate": 665.61
        }, {
            "currencyCode": "CZK",
            "currencySymbol": null,
            "currencyName": "Чешская крона",
            "currencyRate": 21.45
        }, {
            "currencyCode": "SEK",
            "currencySymbol": null,
            "currencyName": "Шведская крона",
            "currencyRate": 51.02
        }, {
            "currencyCode": "CHF",
            "currencySymbol": null,
            "currencyName": "Швейцарский франк",
            "currencyRate": 549.15
        }, {
            "currencyCode": "ZAR",
            "currencySymbol": null,
            "currencyName": "Южно-африканский ранд",
            "currencyRate": 32.71
        }, {
            "currencyCode": "KRW",
            "currencySymbol": null,
            "currencyName": "Южно-корейских вон",
            "currencyRate": 41.34
        }, {
            "currencyCode": "JPY",
            "currencySymbol": null,
            "currencyName": "Японская йена",
            "currencyRate": 4.38
        }, {"currencyCode": "KZT", "currencySymbol": null, "currencyName": "Казахстанский тенге", "currencyRate": 1.0}];
    }

    getCurrencies(currencyCodes) {
        const currArray = [];
        for (const cur of currencyCodes) {
            const response = this.getCurrencyByCode(cur);
            if (response) currArray.push(response);
        }

        return currArray;
    }

    getAllCurrency() {
        return this.data;
    }

    getCurrencyByCode(code) {
        const curs = this.getAllCurrency();
        if (!curs) return undefined;
        const cur = curs.filter(cur => cur.currencyCode === code);

        if (cur.length >= 0)
            return cur[0];
        else {
            return undefined;
        }
    }

    loadAllCurrency() {
        console.log("loadAllCurrency");
        return new Promise((resolve, reject) => {
            resolve()
        })
    }
}