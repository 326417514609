import {datePlusSeconds} from "../../utils/DateUtils";

export class CacheService {

    constructor(key, timeToLiveInSeconds) {
        this.key = key;
        this.timeToLiveInSeconds = timeToLiveInSeconds;
    }

    getCachedData() {
        const data = localStorage.getItem(this.key);

        if (data) {
            return JSON.parse(data).data;
        }
        return null;
    }

    cacheDate(data) {
        const cachedObject = {
            metadata: {createdDate: new Date()},
            data: data
        }
        localStorage.setItem(this.key, JSON.stringify(cachedObject));
    }

    metadata() {
        const data = localStorage.getItem(this.key);

        if (data) {
            return JSON.parse(data).metadata;
        }
        return undefined;
    }

    clean() {
        localStorage.setItem(this.key, '');
    }

    isActual() {
        return this.metadata() ? new Date(this.metadata().createdDate) > datePlusSeconds(new Date(), -1 * this.timeToLiveInSeconds) : false;
    }
}
