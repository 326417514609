import RequestService from "./context/RequestService";
import TransactionServiceMock from "./mock/TransactionServiceMock";

export default class TransactionService {
    static instance;

    static getInstance() {
        if (this.instance !== null) {
            if (navigator.userAgent === "ReactSnap") {
                this.instance = new TransactionServiceMock();
            } else {
                this.instance = new TransactionService();
            }
        }

        return this.instance;
    }

    constructor() {
        this.requestService = new RequestService();
    }

    transactionTable(page, rowPerPage, startDate, endDate, category, account, type) {
        let params = "?";
        params += page ? `page=${page}` : "";
        params += rowPerPage ? `&row_per_page=${rowPerPage}` : "";
        params += startDate ? `&start_date=${startDate}` : "";
        params += endDate ? `&end_date=${endDate}` : "";
        params += category && category !== '-' ? `&category=${category}` : "";
        params += account && account !== '-' ? `&account=${account}` : "";
        params += type && type !== '-' ? `&type=${type}` : "";

        return this.requestService.sendSimpleRequest(`/transaction${params}`, {method: 'GET'})
            .then(value => {
                return value.json()
            }).catch(() => {
                return []
            });
    }

    getTransactionSummary() {
        let endDate = new Date();
        let startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);

        return this.requestService.sendSimpleRequest(`/analytics/amount_transactions?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
            {method: 'GET'}
        ).then(value => {
            return value.json()
        }).catch(() => {
            return {'amountTransactions': 0, 'incomeMoney': 0, 'outcomeMoney': 0}
        });
    }

    getLatestTransaction(amount) {
        return this.transactionTable(1, 10)
            .then(value => {
                return value
            }).catch(() => {
                return []
            });
    }

    save(transaction) {
        return this.requestService.sendSimpleRequest(`/transaction`, {
            method: 'POST',
            body: JSON.stringify(transaction)
        })
    }

    update(transaction) {
        return this.requestService.sendSimpleRequest(`/transaction`, {
            method: 'PUT',
            body: JSON.stringify(transaction)
        })
    }

    delete(id) {
        return this.requestService.sendSimpleRequest(`/transaction?id=${id}`, {method: 'DELETE'})
    }
}