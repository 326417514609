import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import './index.css';
import {createRoot} from 'react-dom/client';



const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

if (rootElement.hasChildNodes()) {
    ReactDOM.hydrate(
        <React.StrictMode>
            <App/>
        </React.StrictMode>,
        rootElement
    );
} else {
    root.render(
        <React.StrictMode>
            <App/>
        </React.StrictMode>
    );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
