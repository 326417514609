/*eslint no-useless-constructor: "error"*/
import CategoryService from "../CategoryService";

export default class CategoryServiceMock implements CategoryService {

    constructor() {
        this.data = [
            {"id": "622237ddc4281f4b88602d66", "name": "Еда"},
            {"id": "622237ddc4281f4b88602d67", "name": "Одежда"},
            {"id": "622237ddc4281f4b88602d68", "name": "Логистика"},
            {"id": "622237ddc4281f4b88602d69", "name": "Развлечение"},
            {"id": "622237ddc4281f4b88602d70", "name": "Инвестиции"},
            {"id": "622237ddc4281f4b88602d71", "name": "Циклические"},
        ];
    }

    getAllCategories() {
        return this.data;
    }

    getCategoryById(id) {
        if (this.getAllCategories())
            return this.getAllCategories().filter(cat => cat.id === id)[0];
    }

    updateCategory(cat) {
        return super.updateCategory(cat);
    }

    delete(id) {
        return super.delete(id);
    }

    save(cat) {
        return super.save(cat);
    }

    loadAllCategories() {
        console.log("loadAllCategories");
        return new Promise((resolve, reject) => {
            resolve()
        })
    }
}