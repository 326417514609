import React from "react";
import {useTranslation} from "react-i18next";
import Modal from "react-modal";
import Swal from "sweetalert2";
import CategoryService from "../../services/CategoryService";
import {Button, Col, Form, Row} from "react-bootstrap";

export default function CategoryForm({
                                         categoryData,
                                         isShow = false,
                                         closeModal = () => {
                                         },
                                         callBackSave = () => {
                                         },
                                         callBackDeleted = () => {

                                         }
                                     }) {
    const {t} = useTranslation();

    const categoryService = CategoryService.getInstance();

    return <>
        <Modal
            isOpen={isShow}
            ariaHideApp={false}
            contentLabel={t('category')}>

            <div className="d-flex justify-content-between">
                <h2>{t('category')}</h2>
                <button onClick={closeModal} className="btn font-weight-bold">&times;</button>
            </div>

            <Form className="mt-2" onSubmit={event => {
                event.preventDefault();
                if (categoryData){
                    categoryService.updateCategory({
                        id: categoryData.id,
                        name: event.target.elements.name.value
                    })
                        .then(res => {
                            if (res.ok) {
                                callBackSave();
                            }
                        });
                }else {
                    categoryService.save({
                        name: event.target.elements.name.value
                    })
                        .then(res => {
                            if (res.ok) {
                                callBackSave();
                            }
                        });
                }
            }}>
                <Form.Group className="mb-2">
                    <Form.Label htmlFor="account-label">{t('category.name')}</Form.Label>
                    <Form.Control type="text" className="form-control" id="account-label" defaultValue={categoryData ? categoryData.name : ''} name="name"/>
                </Form.Group>

                <Form.Group>
                    <Row>
                        <Col>
                            <Button type="submit" className="w-100">{t('btn.save')}</Button>
                        </Col>
                        <Col>
                            <Button type="button" variant={"danger"} className="w-100"
                                    onClick={() => {
                                        Swal.fire({
                                            title: t('category.delete'),
                                            showCancelButton: true,
                                            confirmButtonText: t('btn.delete'),
                                            cancelButtonText: t('btn.cancel'),
                                            html: "<p>" + t('category.after.deleting') + "</p>"
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                categoryService.delete(categoryData.id)
                                                    .then(res => {
                                                        if (res.ok) {
                                                            callBackDeleted()
                                                        } else {
                                                            Swal.fire(t('error.SERVER_NOT_AVAILABLE'), '', 'error');
                                                        }
                                                    });
                                            } else if (result.isDenied) {
                                                Swal.close();
                                            }
                                        })
                                    }}>{t('btn.delete')}
                            </Button>
                        </Col>
                    </Row>
                </Form.Group>
            </Form>
        </Modal>
    </>

}