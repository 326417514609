import css from './Login.module.css';
import commonCss from '../../../Common.module.css';
import React, {useEffect, useRef} from 'react';
import AuthService from "../../../services/AuthService";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {Form} from "react-bootstrap";

export default function Login() {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const authService = AuthService.getInstance(navigate, t);
    const form = {username: useRef(), password: useRef()};

    function sendForm(event) {
        event.preventDefault();
        authService.login(form.username.current.value, form.password.current.value);
    }

    useEffect(() => {
        AuthService.redirectIfAuth(navigate);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={css.wrapper}>
            <section id={css.login_section}>
                <div className={`container ${commonCss.card} ${css.form}`}>
                    <div className="row">
                        <div className="col">
                            <div className="d-flex align-items-center mb-3">
                                <Link to={"/"}><FontAwesomeIcon size={"1x"} icon={faArrowLeft}
                                                                color={"#5abe91"}/></Link>
                                <h2 className={`${css.title}`}>{t('page.login')}</h2>
                            </div>
                            <form className="loginbox" onSubmit={event => sendForm(event)}>
                                <Form.Group className={"mt-1"}>
                                    <Form.Control placeholder={t('form.login')}
                                                  className="form-control" ref={form.username}/>
                                </Form.Group>
                                <Form.Group className={"mt-1"}>
                                    <Form.Control placeholder={t('form.password')}
                                                  type="password"
                                                  className="form-control" ref={form.password}/>
                                </Form.Group>
                                <button className="btn btn-primary w-100 mt-2" type="submit">{t('btn.login')}</button>
                            </form>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col">
                            <Link to={'/auth/registration'}>{t('btn.registration')}</Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );

}
