import {beauty_currency} from "../../redux/common";
import React from "react";
import PieChart from "../charts/PieChart";
import {getGreenGradient, getRedGradient} from "../../redux/ColorUtils";
import {t} from "i18next";
import css from "../../pages/analytics/AnalyticsPage.module.css";


export function AccountAnalyticCard(props){

    return <div key={`accountAnalytics-${JSON.stringify(props)}`} className={`p-1 ${css.analytics_card}`}>
        <div className="card p-3">
            <h4 className="text-center">{props.name}</h4>
            <PieChart
                width={300}
                height={300}
                radius={150}
                radiusInner={75}
                data={[
                    {
                        name: beauty_currency(props.outcomeMoneyTenge, "KZT", "narrowSymbol"),
                        value: props.outcomeMoneyTenge,
                        color: getRedGradient("redGradient")
                    },
                    {
                        name: beauty_currency(props.incomeMoneyTenge - props.outcomeMoneyTenge, "KZT", "narrowSymbol"),
                        value: props.incomeMoneyTenge - props.outcomeMoneyTenge,
                        color: getGreenGradient("green")
                    }]}/>
            <div className={css.card_chart_body}>
                <div>
                    <p>
                        <span>{t('INCOME')}: </span> {beauty_currency(props.incomeMoneyTenge, "KZT", "narrowSymbol")}
                        ({beauty_currency(props.incomeMoney, props.currency, "narrowSymbol")})
                    </p>
                </div>
                <div>
                    <p>
                        <span>{t('balance')}: </span> {beauty_currency(props.incomeMoneyTenge - props.outcomeMoneyTenge, "KZT", "narrowSymbol")}
                        ({beauty_currency(props.incomeMoney - props.outcomeMoney, props.currency, "narrowSymbol")})
                    </p>
                </div>
                <div>
                    <p>
                        <span>{t('OUTCOME')}: </span> {beauty_currency(props.outcomeMoneyTenge, "KZT", "narrowSymbol")}
                        ({beauty_currency(props.outcomeMoney, props.currency, "narrowSymbol")})
                    </p>
                </div>
            </div>
        </div>
    </div>
}