import React, {useRef} from "react";
import {createTheme} from 'react-data-table-component';
import css from "../google_delete_user_page/GoogleDeleteUserPage.module.css";
import commonCss from "../../Common.module.css";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import logoImage from '../../img/logo/logo512x512.png'
import Config from "../../conf";
import Swal from "sweetalert2";

createTheme('solarized', {
  text: {
    primary: '#268bd2',
    secondary: '#2aa198',
  },
  background: {
    default: '#002b36',
  },
  context: {
    background: '#cb4b16',
    text: '#FFFFFF',
  },
  divider: {
    default: '#073642',
  },
  action: {
    Button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
});

export default function GoogleDeleteUserPage() {
  function sendForm(event) {
    event.preventDefault();
    Swal.showLoading();

    fetch(`${Config.BASE_URL}/auth/usr/personal-data`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            username: form.username.current.value,
            password: form.password.current.value
          })
        }).then(response => {
      Swal.close();
      if (response.status === 200) {
        alert('Аккаунт удален');
      } else {
        response.text().then(value => alert(value))
      }
      return response;
    })
  }

  const {t} = useTranslation();
  const form = {username: useRef(), password: useRef()};

  return <div className={css.wrapper}>
    <div className={'w-100'}>
      <div className={'container'}>
        <div className={'d-flex justify-content-center mt-3'}>
          <img src={logoImage} alt="logo" width={50} className={'mx-2'}/>
          <h1>Finance Helper</h1>
        </div>
        <section id={css.delete_account_section}>
          <div
              className={`${commonCss.card} ${css.delete_account_form_container}`}>
            <form onSubmit={event => sendForm(event)}>
              <div className="d-flex align-items-start mb-3 pt-3">
                <Link to={"/"}><FontAwesomeIcon size={"1x"} icon={faArrowLeft}
                                                color={"#5abe91"}/></Link>
              </div>
              <Form.Group className={"mt-1"}>
                <Form.Control placeholder={t('form.login')}
                              className="form-control" ref={form.username}/>
              </Form.Group>
              <Form.Group className={"mt-1"}>
                <Form.Control placeholder={t('form.password')}
                              type="password"
                              className="form-control" ref={form.password}/>
              </Form.Group>
              <button className={'btn btn-danger'}>Delete account</button>
            </form>
          </div>
        </section>

        <section id={css.instruction_section} className={`${commonCss.card}`}>

          <div>
            <h3>Инструкция</h3>
            <p>Чтобы удалить аккаунт:</p>
            <ol>
              <li>Введите логин и пароль</li>
              <li>Нажмите кнопку "Delete account"</li>
              <li>Данные аккаунта полностью удалены</li>
            </ol>
          </div>

          <hr/>

          <div>
            <h3>Instructions</h3>
            <p>To delete an account:</p>
            <ol>
              <li>Enter login and password</li>
              <li>Click the "Delete account" button</li>
              <li>Account data has been completely deleted</li>
            </ol>
          </div>

          <hr/>

          <div>
            <h3>Нұсқаулар</h3>
            <p>Есептік жазбаны жою үшін:</p>
            <ol>
              <li>Логин мен парольді енгізіңіз</li>
              <li>«Delete account» түймесін басыңыз</li>
              <li>Есептік жазба деректері толығымен жойылды</li>
            </ol>
          </div>

          <hr/>

          <small>Made by developer: Shertaev Karim</small>
        </section>
      </div>
    </div>

    {/*<Footer/>*/}
  </div>
}