import React from "react";
import {useTranslation} from "react-i18next";
import Modal from "react-modal";
import {dateISOStr2LocalString} from "../../utils/DateUtils";
import {Button, Col, Form, Row} from "react-bootstrap";
import TransactionService from "../../services/TransactionService";

export default function TransactionForm({
                                            transactionData,
                                            accounts,
                                            categories,
                                            isShow = false,
                                            closeModal = () => {
                                            },
                                            callBack = () => {
                                            },
                                        }) {
    const {t} = useTranslation();

    const transactionService = TransactionService.getInstance();

    let accountOptions = [];
    let categoryOptions = [];

    if (accounts) accountOptions = accounts.map(acc => <option key={`transaction_form_${acc.id}`} value={acc.id}>{acc.name}</option>);
    if (categories) categoryOptions = categories.map(cat => <option key={`transaction_form_${cat.id}`} value={cat.id}>{cat.name}</option>);

    return <>
        <Modal
            isOpen={isShow}
            ariaHideApp={false}
            contentLabel="Transaction">

            <div className="d-flex justify-content-between">
                <h2>{t('transaction')}</h2>
                <Button onClick={closeModal} variant={"light"} className="font-weight-bold bg-transparent">&times;</Button>
            </div>

            <Form className="mt-2" onSubmit={event => {
                event.preventDefault();
                const transaction = {
                    "account": event.target.elements.accountId.value,
                    "amount": event.target.elements.amount.value,
                    "category": event.target.elements.category.value,
                    "transactionType": event.target.elements.transactionType.value,
                    "transactionDate": new Date(event.target.elements.transactionDate.value).toISOString(),
                    "description": event.target.elements.description.value
                };

                if (transactionData) {
                    transaction["id"] = transactionData.id;
                    transactionService.update(transaction)
                        .then(res => {
                            if (res.ok) {
                                callBack();
                            }
                        });
                } else {
                    transactionService.save(transaction)
                        .then(res => {
                            if (res.ok) {
                                callBack();
                            }
                        });
                }

            }}>
                <Form.Group className="mb-1">
                    <Form.Label htmlFor="transaction-date">{t('transaction date')}</Form.Label>
                    <Form.Control type="datetime-local" className="form-control" name="transactionDate"
                           id="transaction-date"
                           defaultValue={transactionData ? dateISOStr2LocalString(transactionData.transactionDate) : undefined}
                           required/>
                </Form.Group>

                <Form.Group className="mb-1">
                    <Form.Label htmlFor="amount-of-money">{t('amount of money')}</Form.Label>
                    <Form.Control type="number" className="form-control" id="amount-of-money" name="amount"
                           defaultValue={transactionData ? transactionData.amount : undefined} required/>
                </Form.Group>

                <Form.Group className="mb-1">
                    <Form.Label htmlFor="transaction-type">{t('transaction type')}</Form.Label>
                    <Form.Select className="form-control" id="transaction-type" name="transactionType"
                            defaultValue={transactionData ? transactionData.transactionType : undefined} required>
                        <option value="INCOME">{t('INCOME')}</option>
                        <option value="OUTCOME">{t('OUTCOME')}</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-1">
                    <Form.Label htmlFor="transaction-category">{t('category')}</Form.Label>
                    <Form.Select className="form-control" id="transaction-category" name="category"
                            defaultValue={transactionData ? transactionData.categoryId : undefined} required>
                        {categoryOptions}
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-1">
                    <Form.Label htmlFor="transaction-account">{t('account')}</Form.Label>
                    <Form.Select className="form-control" id="transaction-account" name="accountId"
                            defaultValue={transactionData ? transactionData.accountId : undefined} required>
                        {accountOptions}
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-1">
                    <Form.Label htmlFor="transaction-description">{t('description')}</Form.Label>
                    <Form.Control as="textarea" rows={2} id="transaction-description"
                              defaultValue={transactionData ? transactionData.description : ""}
                              name="description" >
                        </Form.Control>
                </Form.Group>

                <Form.Group className="mt-2">
                    <Row>
                        <Col>
                            <Button type="submit" variant="primary" className="w-100">{t('btn.save')}</Button>
                        </Col>
                        <Col hidden={!transactionData}>
                            <Button type="button" variant={"danger"} className="w-100"
                                    onClick={() => {
                                        transactionService.delete(transactionData.id)
                                            .then(res => {
                                                if (res.ok) {
                                                    callBack();
                                                }
                                            });
                                    }}>{t('btn.delete')}
                            </Button>
                        </Col>
                    </Row>
                </Form.Group>
            </Form>
        </Modal>
    </>

}