import RequestService from "./context/RequestService";
import {CacheService} from "./context/CacheService";
import CurrencyServiceMock from "./mock/CurrencyServiceMock";

export default class CurrencyService {
    static instance;

    static getInstance() {
        if (this.instance === undefined) {
            if (navigator.userAgent === "ReactSnap") {
                this.instance = new CurrencyServiceMock();
            } else {
                this.instance = new CurrencyService();
            }
        }

        return this.instance;
    }

    constructor() {
        this.currencies = [];
        this.requestService = new RequestService();
        this.cache = new CacheService('currency', 60 * 60);
    }

    loadAllCurrency() {
        return this.requestService.sendSimpleRequest(`/currency`, {method: 'GET'}).then(value => {
            if (value.ok) {
                value.json().then(data => this.cache.cacheDate(data));
            }
        });
    }

    getCurrencies(currencyCodes) {
        const currArray = [];
        for (const cur of currencyCodes) {
            const response = this.getCurrencyByCode(cur);
            if (response) currArray.push(response);
        }

        return currArray;
    }


    getAllCurrency() {
        return this.cache.getCachedData();
    }

    getCurrencyByCode(code) {
        const curs = this.getAllCurrency();
        if (!curs) return undefined;
        const cur = curs.filter(cur => cur.currencyCode === code);

        if (cur.length >= 0)
            return cur[0];
        else {
            return undefined;
        }
    }
}