import React, {useEffect, useState} from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import DataTable, {createTheme} from 'react-data-table-component';
import TransactionService from "../../services/TransactionService";
import AccountService from "../../services/AccountService";
import CategoryService from "../../services/CategoryService";
import {getCurrentLanguage} from "../../redux/common";
import {localISOString2Date} from "../../utils/DateUtils"
import edit_icon from "../../img/icons/edit_icon.webp";
import css from "../home_page/HomePage.module.css";
import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";
import add_icon from "../../img/icons/add_icon.webp";
import {useNavigate} from "react-router-dom";
import AuthService from "../../services/AuthService";
import {Button, Col, Form, Row} from "react-bootstrap";
import TransactionForm from "../../components/forms/TransactionForm";

createTheme('solarized', {
    text: {
        primary: '#268bd2',
        secondary: '#2aa198',
    },
    background: {
        default: '#002b36',
    },
    context: {
        background: '#cb4b16',
        text: '#FFFFFF',
    },
    divider: {
        default: '#073642',
    },
    action: {
        Button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
    },
});

export default function HistoryPage() {
    const navigate = useNavigate();
    const transactionService = TransactionService.getInstance();
    const categoryService = CategoryService.getInstance();
    const accountService = AccountService.getInstance();
    const defaultPage = 1;
    const defaultRowByPage = 10;
    const defaultTotalRows = 0;
    const defaultStartDate = null;
    const defaultEndDate = null;

    const [page, setPage] = useState(defaultPage);
    const [rowByPage, setRowByPage] = useState(defaultRowByPage);
    const [isShowTransactionModal, setIsShowTransactionModal] = useState(false);
    const [data, setData] = useState();
    const [totalRows, setTotalRows] = useState(defaultTotalRows);
    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);
    const [category, setCategory] = useState();
    const [account, setAccount] = useState();
    const [type, setType] = useState();
    const [categoryList, setCategoryList] = useState([]);
    const [accountList, setAccountList] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [accountOptions, setAccountOptions] = useState([]);
    const [transaction, setTransaction] = useState(undefined);
    const {t, i18n} = useTranslation();


    useEffect(() => {
        AuthService.redirectIfNotAuth(navigate);
        if (AuthService.isAuth()) {
            i18n.changeLanguage(getCurrentLanguage())
                .then(() => {
                    Swal.fire({
                        title: 'Please wait.',
                        html: 'Data is loading',
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                }).then(() => {
                Promise.all([
                    categoryService.loadAllCategories(),

                    accountService.loadAccounts(),

                    transactionService.transactionTable(page, rowByPage).then(r => {
                        setData(r.transactions);
                        setTotalRows(r.totalAmount);
                    })
                ]).then(() => {
                    const categories = categoryService.getAllCategories();
                    const accounts = accountService.getAllAccounts();

                    setCategoryOptions(categories.map(cat => <option key={`${cat.id}`}
                                                                     value={cat.id}>{cat.name}</option>));
                    setAccountOptions(accounts.map(acc => <option key={`${acc.id}`}
                                                                  value={acc.id}>{acc.name}</option>));

                    setCategoryList(categories);
                    setAccountList(accounts);

                    Swal.close();
                });
            });
        }
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    function filter(event) {
        if (event && !event.page) {
            event.preventDefault();
            const startDate = localISOString2Date(event.target.elements.startDate.value);
            const endDate = localISOString2Date(event.target.elements.endDate.value);

            transactionService.transactionTable(page, rowByPage,
                startDate ? startDate.toISOString() : undefined,
                endDate ? endDate.toISOString() : undefined,
                event.target.elements.category.value,
                event.target.elements.account.value,
                event.target.elements.type.value).then(r => {
                setData(r.transactions);
                setTotalRows(r.totalAmount);
            });

            setStartDate(startDate ? startDate.toISOString() : undefined);
            setEndDate(endDate ? endDate.toISOString() : undefined);
            setCategory(event.target.elements.category.value);
            setAccount(event.target.elements.account.value);
            setType(event.target.elements.type.value);

        } else if (event) {
            transactionService.transactionTable(event.page, event.currentRowsPerPage,
                startDate, endDate,
                category, account,
                type).then(r => {
                setData(r.transactions);
                setTotalRows(r.totalAmount);
            })
        } else {
            transactionService.transactionTable(page, rowByPage,
                startDate, endDate,
                category, account,
                type).then(r => {
                setData(r.transactions);
                setTotalRows(r.totalAmount);
            })
        }
    }


    return <div className={css.wrapper}>
        <div>
            <Header/>

            <div className="container">
                <div className="row">
                    <div className="col mb-3 mt-3 card pt-3">
                        <h4>{t('page.history')}</h4>
                        <Form className="form-row" onSubmit={event => filter(event)}>
                            <Row>
                                <Col className="col-6 col-md-4 mb-2">
                                    <label htmlFor="startDate">{t('form.start_date')}</label>
                                    <input type="datetime-local" className="form-control w-100" name="startDate"
                                           id="startDate"/>
                                </Col>

                                <Col className="col-6 col-md-4 mb-2">
                                    <label htmlFor="endDate">{t('form.end_date')}</label>
                                    <input type="datetime-local" className="form-control w-100" name="endDate"
                                           id="endDate"/>
                                </Col>

                                <Col className="col-6 col-md-4 mb-2">
                                    <label htmlFor="category">{t('form.category')}</label>
                                    <select name="category" id="category" className="form-control w-100">
                                        <option key="category_none" value="-">-</option>
                                        {categoryOptions}
                                    </select>
                                </Col>

                                <Col className="col-6 col-md-4 mb-2">
                                    <label htmlFor="account">{t('form.account')}</label>
                                    <select name="account" id="account" className="form-control">
                                        <option key="account_none" value="-">-</option>
                                        {accountOptions}
                                    </select>
                                </Col>

                                <Col className="col-6 col-md-4 mb-2">
                                    <label htmlFor="type">{t('form.type')}</label>
                                    <select name="type" id="type" className="form-control">
                                        <option key="type_none" value="-">-</option>
                                        <option key="INCOME" value="INCOME">{t('INCOME')}</option>
                                        <option key="OUTCOME" value="OUTCOME">{t('OUTCOME')}</option>
                                    </select>
                                </Col>

                                <Col className="mb-2 d-flex align-items-end">
                                    <Button type="submit" variant="primary" className="h-fit">{t('btn.filter')}</Button>
                                </Col>
                            </Row>
                        </Form>
                        <DataTable
                            paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                            pagination={true}
                            paginationServer={true}
                            paginationPerPage={rowByPage}
                            paginationTotalRows={totalRows}
                            columns={[
                                {
                                    name: t('table.header.Date'),
                                    minWidth: 150,
                                    selector: (row, index) => new Date(row['transactionDate']).toLocaleString(),
                                }, {
                                    name: t('table.header.Account'),
                                    selector: (row, index) => {
                                        const acc = accountService.getAccountById(row['accountId']);
                                        if (acc !== undefined)
                                            return acc.name;
                                        return "";
                                    },
                                },
                                {
                                    name: t('table.header.Category'),
                                    selector: (row, index) => {
                                        const cat = categoryService.getCategoryById(row['categoryId']);
                                        if (cat !== undefined)
                                            return cat.name;
                                        return "";
                                    },
                                },
                                {
                                    name: t('table.header.Type'),
                                    selector: (row, index) => t(row['transactionType']),
                                },
                                {
                                    name: t('table.header.Amount'),
                                    selector: (row, index) => row['amount'],
                                },
                                {
                                    name: t('table.header.Currency'),
                                    selector: (row, index) => row['currency']['currencyCode'],
                                },
                                {
                                    name: t('table.header.Rate'),
                                    selector: (row, index) => row['currency']['currencyRate'],
                                },
                                {
                                    name: <img src={add_icon} alt="add_transactiion"
                                               className={css.add_icon}
                                               onClick={() => {
                                                   setTransaction(undefined);
                                                   setIsShowTransactionModal(true);
                                               }}/>,
                                    selector: (row, index) => <img src={edit_icon} alt="edit_transaction"
                                                                   className={css.change_icon} onClick={() => {
                                        setTransaction(row);
                                        setIsShowTransactionModal(true);
                                    }
                                    }/>,
                                }
                            ]}
                            data={data}
                            onChangePage={(page, totalRows) => {
                                setPage(page);

                                filter({page: page, currentRowsPerPage: rowByPage});
                            }}
                            onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                                setRowByPage(currentRowsPerPage);
                                setPage(currentPage);

                                filter({page: page, currentRowsPerPage: currentRowsPerPage});
                            }}/>
                    </div>
                </div>
            </div>

            <TransactionForm isShow={isShowTransactionModal}
                             transactionData={transaction}
                             categories={categoryList}
                             accounts={accountList}
                             closeModal={
                                 () => {
                                     setIsShowTransactionModal(false)
                                 }
                             }
                             callBack={
                                 () => {
                                     setIsShowTransactionModal(false)
                                     filter();
                                 }
                             }
                             key="TransactionForm"/>
        </div>

        <Footer/>
    </div>
}