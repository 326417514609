/*eslint no-useless-constructor: "error"*/
import AuthService from "../AuthService";

export default class AuthServiceMock implements AuthService {

    login(username, password) {
        super.login(username, password);
    }

    registration(username, firstname, lastname, patronymic, email, password, passwordConf) {
        super.registration(username, firstname, lastname, patronymic, email, password, passwordConf);
    }

    logout() {
        super.logout();
    }

    isAuth() {
        return !['/', '/auth/login', '/auth/registration'].includes(window.location.pathname);
    }
}